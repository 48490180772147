import React from 'react';
import { CircularProgress } from "@mui/material";

const CourseSelector = ({ courses, onSelect, loading }) => {
  // Ordenar los cursos de la A a la Z
  const sortedCourses = [...courses].sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <div className="mb-6">
      <label htmlFor="course-selector" className="block text-gray-700 font-semibold mb-2">
        Seleccionar Curso
      </label>
      <select
        id="course-selector"
        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200"
        onChange={(e) => onSelect(e.target.value)}
        disabled={loading}
      >
        <option value="">-- Selecciona un curso --</option>
        {sortedCourses.map((course) => (
          <option key={course.id} value={course.id}>
            {course.title}
          </option>
        ))}
      </select>
      {loading && (
        <div className="flex justify-center items-center mt-3">
          <CircularProgress size={24} color="primary" />
        </div>
      )}
    </div>
  );
};

export default CourseSelector;

