// Bachillerato.js
import React from 'react';
import HeaderProgram from '../components/HeaderProgram'; 
import PlanDeEstudio from '../components/PlanDeEstudio';
import BotonEnlace from '../components/BotonEnlace';
import Requisitos from '../components/Requisitos';
import Galeria from '../components/Galeria';
import bachillerImage from '../assets/img/portfolio/fullsize/bachiller.jpg';
import thumbnailImage1 from '../assets/img/portfolio/thumbnails/1.jpg';
import thumbnailImage3 from '../assets/img/portfolio/thumbnails/3.jpg';
import thumbnailImage4 from '../assets/img/portfolio/thumbnails/4.jpg';
import thumbnailImage5 from '../assets/img/portfolio/thumbnails/5.jpg';
import thumbnailImage6 from '../assets/img/portfolio/thumbnails/6.jpg';
import thumbnailImage7 from '../assets/img/portfolio/thumbnails/7.jpg';

const Bachillerato = () => {
  const bachillerPlan = [
    { title: 'Ciclo I (Grado 1° y 2°)', description: 'Lenguaje, Matemáticas, Biología, Ciencias Sociales' },
    { title: 'Ciclo II (Grado 3°, 4° y 5°)', description: 'Lenguaje, Matemáticas, Ciencias Naturales, Ciencias Sociales' },
    { title: 'Ciclo III (Grado 6° y 7°)', description: 'Literatura, Matemáticas, Biología, Ciencias Sociales, Ética y Valores, Geometría, Inglés, TICS' },
    { title: 'Ciclo IV (Grado 8° y 9°)', description: 'Literatura, Matemáticas, Biología, Ciencias Sociales, Ética y Valores, Geometría, Inglés, TICS' },
    { title: 'Ciclo V (Grado 10°)', description: 'Literatura, Matemáticas, Física, Filosofía, Competencias Ciudadanas, Química, Inglés, TICS' },
    { title: 'Ciclo VI (Grado 11°)', description: 'Literatura, Matemáticas, Física, Filosofía, Competencias Ciudadanas, Química, Inglés, TICS' },
  ];

  const requisitos = [
    { title: 'Edad', description: '16 años o más' },
    { title: 'Escolaridad', description: 'Certificado de grados cursados' },
    { title: 'Copia', description: 'Documento de Identidad' },
    { title: 'Digital', description: '1 foto tamaño 3x4' },
    { title: 'Copia', description: 'Comprobante de Pago' },
  ];

  const imagenes = [
    { src: thumbnailImage1, link: '/teologia', alt: 'Imagen de Galería 1' },
    { src: thumbnailImage3, link: '/infancia', alt: 'Imagen de Galería 2' },
    { src: thumbnailImage4, link: '/administrativo', alt: 'Imagen de Galería 3' },
    { src: thumbnailImage5, link: '/musica', alt: 'Imagen de Galería 4' },
    { src: thumbnailImage6, link: '/ingles', alt: 'Imagen de Galería 5' },
    { src: thumbnailImage7, link: '/contable', alt: 'Imagen de Galería 6' },
  ];

  return (
    <div>
      <HeaderProgram backgroundImage={bachillerImage} />
      <main className="container my-5">
        <PlanDeEstudio title="Bachillerato para Adultos" subtitle="Plan de Estudio por Ciclos" items={bachillerPlan} />
        
        {/* Botón de Costos */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace
              className="btn btn-masinfo btn-xl"
              href="https://wa.me/573216200693?text=Hola,%20quiero%20más%20información%20sobre%20el%20Bachillerato%20y%20costos"
              target="_blank"
              rel="noopener noreferrer"
              title="Contacta con nosotros en WhatsApp"
            >
              <i className="bi-whatsapp me-2"></i> Costos y más información
            </BotonEnlace>
          </div>
        </section>
        
        {/* Requisitos */}
        <Requisitos title="Requisitos" requisitos={requisitos} />
        
        {/* Botón de Pre-inscripción */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace className="btn btn-primary btn-xl" href="/preinscripcion">
              Pre-inscribirme
            </BotonEnlace>
          </div>
        </section>
        
        {/* Galería */}
        <Galeria title="Galería" imagenes={imagenes} />
      </main>
    </div>
  );
};

export default Bachillerato;

