// src/components/Message.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FiInfo, FiCheckCircle, FiAlertTriangle, FiXCircle } from 'react-icons/fi';

const Message = ({ type = 'info', children }) => {
  let bgColor, textColor, icon;

  switch (type) {
    case 'success':
      bgColor = 'bg-green-100';
      textColor = 'text-green-700';
      icon = <FiCheckCircle className="w-6 h-6 text-green-500" />;
      break;
    case 'error':
      bgColor = 'bg-red-100';
      textColor = 'text-red-700';
      icon = <FiXCircle className="w-6 h-6 text-red-500" />;
      break;
    case 'warning':
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-700';
      icon = <FiAlertTriangle className="w-6 h-6 text-yellow-500" />;
      break;
    case 'info':
    default:
      bgColor = 'bg-blue-100';
      textColor = 'text-blue-700';
      icon = <FiInfo className="w-6 h-6 text-blue-500" />;
      break;
  }

  return (
    <div className={`flex items-start p-4 rounded-lg ${bgColor} mt-2`}>
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-3">
        <p className={`text-sm font-medium ${textColor}`}>{children}</p>
      </div>
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  children: PropTypes.node.isRequired,
};

export default Message;
