// src/components/Sidebar/Sidebar.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FiLock, FiCheckCircle } from 'react-icons/fi';

const Sidebar = ({
  lessons,
  currentLessonIndex,
  completedLessons,
  setCurrentLessonIndex,
}) => {
  return (
    <div className="bg-card-background p-6 rounded-lg shadow-md max-w-sm w-full">
      <h2 className="text-xl font-semibold text-primary mb-4">Contenido del Curso</h2>
      <ul className="space-y-2">
        {lessons.map((lesson, index) => {
          const isCompleted = completedLessons.includes(lesson.id);
          const isUnlocked =
            index === 0 || completedLessons.includes(lessons[index - 1].id);
          const isActive = index === currentLessonIndex;

          return (
            <li key={lesson.id}>
              <button
                onClick={() => isUnlocked && setCurrentLessonIndex(index)}
                disabled={!isUnlocked}
                className={`flex items-center justify-between w-full p-3 rounded-lg text-left font-poppins transition-colors duration-200
                  ${
                    isActive
                      ? 'bg-primary text-white'
                      : isUnlocked
                      ? 'bg-white text-primary hover:bg-primary-hover hover:text-white'
                      : 'bg-gray-200 text-gray-500 cursor-not-allowed'
                  }
                `}
              >
                <span className="flex-1">{lesson.title}</span>
                <span className="ml-2">
                  {isCompleted ? (
                    <FiCheckCircle className="text-green-500" size={20} />
                  ) : !isUnlocked ? (
                    <FiLock className="text-gray-500" size={20} />
                  ) : null}
                </span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

// Definición de PropTypes para mejor mantenimiento y debugging
Sidebar.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentLessonIndex: PropTypes.number.isRequired,
  completedLessons: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCurrentLessonIndex: PropTypes.func.isRequired,
};

export default Sidebar;
