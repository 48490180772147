import React from 'react';

const Requisitos = ({ title, requisitos }) => {
  return (
    <section className="my-5">
      <h2 className="text-center">{title}</h2>
      <div className="row justify-content-center">
        <div className="col-lg-5">
          {requisitos.map((req, index) => (
            <div key={index} className="course-info d-flex justify-content-between align-items-center">
              <h5>{req.title}</h5>
              <p>{req.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Requisitos;
