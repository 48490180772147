// src/firebase/firestore/courses.js
import { db } from '../firebaseConfig';
import { 
  collection, 
  addDoc, 
  doc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  query, 
  where 
} from 'firebase/firestore';

// Obtener todos los cursos
export const getCourses = async () => {
  try {
    const coursesSnapshot = await getDocs(collection(db, 'courses'));
    return coursesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error al obtener los cursos:', error);
    throw error;
  }
};

// Agregar un nuevo curso
export const addCourse = async (courseData) => {
  try {
    await addDoc(collection(db, 'courses'), courseData);
  } catch (error) {
    console.error('Error al agregar el curso:', error);
    throw error;
  }
};

// Actualizar un curso existente
export const updateCourse = async (courseId, courseData) => {
  try {
    const courseDocRef = doc(db, 'courses', courseId);
    await updateDoc(courseDocRef, courseData);
  } catch (error) {
    console.error('Error al actualizar el curso:', error);
    throw error;
  }
};

// Eliminar un curso
export const deleteCourse = async (courseId) => {
  try {
    const courseDocRef = doc(db, 'courses', courseId);
    await deleteDoc(courseDocRef);
  } catch (error) {
    console.error('Error al eliminar el curso:', error);
    throw error;
  }
};

// Obtener detalles de un curso específico, incluyendo módulos y lecciones
export const getCourseDetails = async (courseId) => {
  try {
    const courseDocRef = doc(db, 'courses', courseId);
    const courseDoc = await getDoc(courseDocRef);

    if (courseDoc.exists()) {
      const courseData = { id: courseDoc.id, ...courseDoc.data() };

      // Obtener módulos
      const modulesSnapshot = await getDocs(collection(courseDocRef, 'modules'));
      const modules = await Promise.all(modulesSnapshot.docs.map(async (moduleDoc) => {
        const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };
        // Obtener lecciones para cada módulo
        const lessonsSnapshot = await getDocs(collection(moduleDoc.ref, 'lessons'));
        moduleData.lessons = lessonsSnapshot.docs.map(lessonDoc => ({ id: lessonDoc.id, ...lessonDoc.data() }));
        return moduleData;
      }));

      courseData.modules = modules.sort((a, b) => a.createdAt - b.createdAt);

      return courseData;
    } else {
      throw new Error('Curso no encontrado');
    }
  } catch (error) {
    console.error('Error al obtener los detalles del curso:', error);
    throw error;
  }
};

// Validar el código de acceso en la base de datos (Ahora en students)
export const validateAccessCode = async (code) => {
  try {
    const q = query(collection(db, 'students'), where('accessCode', '==', code));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return false;
    } else {
      const studentDoc = querySnapshot.docs[0];
      return { studentId: studentDoc.id };
    }
  } catch (error) {
    console.error('Error al validar el código de acceso:', error);
    throw error;
  }
};


export const getModuleTitle = async (courseId, moduleId) => {
  try {
    const moduleDocRef = doc(db, 'courses', courseId, 'modules', moduleId);
    const moduleDoc = await getDoc(moduleDocRef);
    if (moduleDoc.exists()) {
      const moduleData = moduleDoc.data();
      return moduleData.title || 'Módulo sin título';
    } else {
      throw new Error('Módulo no encontrado');
    }
  } catch (error) {
    console.error('Error al obtener el título del módulo:', error);
    throw error;
  }
};
