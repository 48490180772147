import React from 'react';
import HeaderProgram from '../components/HeaderProgram';
import PlanDeEstudio from '../components/PlanDeEstudio';
import BotonEnlace from '../components/BotonEnlace';
import Requisitos from '../components/Requisitos';
import Galeria from '../components/Galeria';
import contableImage from '../assets/img/portfolio/fullsize/contable.jpg';
import thumbnailImage1 from '../assets/img/portfolio/thumbnails/1.jpg';
import thumbnailImage2 from '../assets/img/portfolio/thumbnails/2.jpg';
import thumbnailImage3 from '../assets/img/portfolio/thumbnails/3.jpg';
import thumbnailImage5 from '../assets/img/portfolio/thumbnails/5.jpg';
import thumbnailImage6 from '../assets/img/portfolio/thumbnails/6.jpg';
import thumbnailImage4 from '../assets/img/portfolio/thumbnails/4.jpg';

const Contable = () => {
  const planDeEstudio = [
    { title: 'Contabilidad', description: '' },
    { title: 'Gestión Documental', description: '' },
    { title: 'Producción de Documentos', description: '' },
    { title: 'Nómina', description: '' },
    { title: 'Organización de Juntas', description: '' },
    { title: 'Recursos Humanos', description: '' },
    { title: 'Servicio al Cliente', description: '' },
    { title: 'Emprendimiento', description: '' },
    { title: 'Tics', description: '' },
    { title: 'Ética', description: '' },
    { title: 'Comunicación para la comprensión', description: '' },
    { title: 'Inglés', description: '' },
  ];

  const requisitos = [
    { title: 'Edad', description: '15 años o más' },
    { title: 'Escolaridad', description: 'Certificado 9°' },
    { title: 'Copia', description: 'Documento de Identidad' },
    { title: 'Copia', description: 'Comprobante de Pago' },
  ];

  const imagenes = [
    { src: thumbnailImage2, link: '/bachillerato', alt: 'Imagen de Galería 1' },
    { src: thumbnailImage3, link: '/infancia', alt: 'Imagen de Galería 2' },
    { src: thumbnailImage4, link: '/administrativo', alt: 'Imagen de Galería 3' },
    { src: thumbnailImage5, link: '/musica', alt: 'Imagen de Galería 4' },
    { src: thumbnailImage6, link: '/ingles', alt: 'Imagen de Galería 5' },
    { src: thumbnailImage1, link: '/teologia', alt: 'Imagen de Galería 6' },
  ];

  return (
    <div>
    

      {/* Header con Imagen de Fondo Relevante */}
      <HeaderProgram backgroundImage={contableImage} />

      {/* Contenido Principal */}
      <main className="container my-5">
        {/* Sección: Plan de Estudio */}
        <PlanDeEstudio title="Plan de Estudio" items={planDeEstudio} />

        {/* Sección: Botón de Costos */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace
              className="btn btn-masinfo btn-xl"
              href="https://wa.me/573216200693?text=Hola,%20quiero%20más%20información%20sobre%20el%20programa%20Técnico%20Laboral%20en%20Auxiliar%20Contable%20y%20sus%20costos"
              target="_blank"
              rel="noopener noreferrer"
              title="Contacta con nosotros en WhatsApp"
            >
              <i className="bi-whatsapp me-2"></i> Costos y más información
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Requisitos */}
        <Requisitos title="Requisitos" requisitos={requisitos} />

        {/* Sección: Botón de Pre-inscripción */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace className="btn btn-primary btn-xl" href="/preinscripcion">
              Pre-inscribirme
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Galería */}
        <Galeria title="Galería" imagenes={imagenes} />
      </main>
    </div>
  );
};

export default Contable;
