// StudentsList.jsx

import React, { useState } from "react";
import {
  FaEnvelope,
  FaCopy,
  FaUserCog,
  FaTrash,
  FaSearch,
  FaEdit,
} from "react-icons/fa";
import Loader from "../Loader/Loader";
import EditStudentForm from "./EditStudentForm"; // Nuevo subcomponente

const StudentsList = ({
  students,
  loading,
  handleSelectStudent,
  handleRemoveStudent,
  handleUpdateStudent, // Nueva prop
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [copiedEmail, setCopiedEmail] = useState(null);
  const [editingStudent, setEditingStudent] = useState(null); // Estado para el estudiante en edición

  // Filtrar estudiantes según el término de búsqueda
  const filteredStudents = students.filter((student) =>
    student.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Función para manejar la copia del código de acceso
  const handleCopy = (email, accessCode) => {
    navigator.clipboard.writeText(accessCode);
    setCopiedEmail(email);
    setTimeout(() => setCopiedEmail(null), 2000); // Reset después de 2 segundos
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mb-6">
      <h3 className="text-xl font-bold text-gray-800 mb-4">Lista de Estudiantes</h3>

      {/* Barra de Búsqueda */}
      <div className="flex items-center bg-gray-100 rounded-md p-2 mb-4">
        <FaSearch className="text-gray-400 mr-2" />
        <input
          type="text"
          placeholder="Buscar por correo electrónico..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow bg-transparent outline-none text-gray-800 placeholder-gray-400"
        />
      </div>

      {/* Condición para mostrar contenido basado en el término de búsqueda */}
      {searchTerm.trim() === "" ? (
        <p className="text-gray-500 text-center">Por favor, ingresa un término de búsqueda para ver los estudiantes.</p>
      ) : loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-4">
          {filteredStudents.length === 0 ? (
            <p className="text-gray-500 text-center">No se encontraron estudiantes.</p>
          ) : (
            filteredStudents.map((student) => (
              <div
                key={student.email}
                className="bg-white border border-gray-200 rounded-md p-4 shadow-sm hover:shadow-md transition-shadow duration-200 flex justify-between items-center"
              >
                {/* Información del Estudiante */}
                <div className="flex items-center gap-4">
                  <FaEnvelope className="text-blue-500 text-2xl" />
                  <div>
                    <p className="text-lg font-semibold text-gray-700">
                      {student.firstName} {student.lastName}
                    </p>{" "}
                    {/* Mostrar nombres y apellidos */}
                    <p className="text-sm text-gray-600">{student.email}</p>{" "}
                    {/* Mostrar correo */}
                    <div className="flex items-center mt-2 relative">
                      <span className="bg-gray-200 px-2 py-1 rounded-md text-sm text-gray-700 mr-2">
                        {student.accessCode}
                      </span>
                      <FaCopy
                        className="text-gray-600 cursor-pointer hover:text-gray-800 transition-colors duration-200"
                        onClick={() => handleCopy(student.email, student.accessCode)}
                        title="Copiar Código"
                      />
                      {copiedEmail === student.email && (
                        <span className="absolute top-[-25px] left-0 bg-green-500 text-white px-2 py-1 rounded-md text-xs opacity-90">
                          ¡Copiado!
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Acciones del Estudiante */}
                <div className="flex gap-2">
                  <button
                    onClick={() => handleSelectStudent(student)}
                    className="flex items-center gap-1 bg-primary text-white py-2 px-3 rounded-md hover:bg-primary-hover transition-colors duration-200"
                    title="Ver Perfil"
                  >
                    <FaUserCog /> Ver Perfil
                  </button>

                  <button
                    onClick={() => setEditingStudent(student)}
                    className="flex items-center gap-1 bg-secondaryblue text-white py-2 px-3 rounded-md hover:bg-secondary transition-colors duration-200"
                    title="Editar Estudiante"
                  >
                    <FaEdit /> Editar
                  </button>

                  <button
                    onClick={() => handleRemoveStudent(student.email)}
                    className="flex items-center gap-1 bg-secondary text-white py-2 px-3 rounded-md hover:bg-secondary-hover transition-colors duration-200"
                    title="Eliminar Estudiante"
                  >
                    <FaTrash /> Eliminar
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}

      {/* Formulario de Edición */}
      {editingStudent && (
        <EditStudentForm
          student={editingStudent}
          onClose={() => setEditingStudent(null)}
          onUpdate={handleUpdateStudent}
        />
      )}
    </div>
  );
};

export default StudentsList;
