import React, { useState, useEffect } from "react";
import { getCourses } from "../../firebase/firestore/courses";
import {
  getCourseModules,
  addModuleToCourse,
  updateModule,
  deleteModule,
} from "../../firebase/firestore/modules";
import CourseSelector from "./CourseSelector";
import ModuleForm from "./ModuleForm";
import { FaPlus, FaEdit, FaTrash, FaSpinner } from "react-icons/fa";

const AdminModules = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showModuleForm, setShowModuleForm] = useState(false);
  const [editingModule, setEditingModule] = useState(null);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    setLoadingCourses(true);
    try {
      const courseData = await getCourses();
      setCourses(courseData);
    } catch (error) {
      setError("Error al cargar los cursos.");
      console.error(error);
    } finally {
      setLoadingCourses(false);
    }
  };

  const fetchModules = async (courseId) => {
    setSelectedCourse(null);
    setModules([]);
    setMessage("");
    setError("");
    setShowModuleForm(false);
    setEditingModule(null);
    if (!courseId) return;

    setLoadingModules(true);
    try {
      const modulesData = await getCourseModules(courseId);
      setModules(modulesData);
      const selected = courses.find((course) => course.id === courseId);
      setSelectedCourse(selected);
    } catch (error) {
      setError("Error al cargar los módulos.");
      console.error(error);
    } finally {
      setLoadingModules(false);
    }
  };

  const handleModuleSubmit = async (moduleData) => {
    setProcessing(true);
    try {
      if (editingModule) {
        await updateModule(selectedCourse.id, editingModule.id, moduleData);
        setMessage("Módulo actualizado exitosamente.");
      } else {
        await addModuleToCourse(selectedCourse.id, moduleData);
        setMessage("Módulo agregado exitosamente.");
      }
      handleCourseSelect(selectedCourse.id); // Recargar los módulos
      setShowModuleForm(false);
      setEditingModule(null);
    } catch (error) {
      setError("Error al guardar el módulo.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleEditModule = (module) => {
    setEditingModule(module);
    setShowModuleForm(true);
    setMessage("");
    setError("");
  };

  const handleDeleteModule = async (moduleId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este módulo?")) {
      setProcessing(true);
      try {
        await deleteModule(selectedCourse.id, moduleId);
        setMessage("Módulo eliminado exitosamente.");
        handleCourseSelect(selectedCourse.id); // Recargar los módulos
      } catch (error) {
        setError("Error al eliminar el módulo.");
        console.error(error);
      } finally {
        setProcessing(false);
      }
    }
  };

  const handleCourseSelect = (courseId) => {
    fetchModules(courseId);
  };

  return (
    <div className="max-w-7xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">Administrar Módulos</h2>

      {/* Alertas de Mensajes */}
      {message && (
        <div className="bg-green-100 text-green-800 p-4 rounded-lg mb-6">
          {message}
        </div>
      )}
      {error && (
        <div className="bg-red-100 text-red-800 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Selección de Curso */}
      <div className="bg-gray-50 p-6 mb-6 rounded-lg shadow-md">
        <CourseSelector
          courses={courses}
          onSelect={handleCourseSelect}
          loading={loadingCourses}
        />
      </div>

      {/* Lista de Módulos */}
      {selectedCourse && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-secondary">
              Módulos para {selectedCourse.title}
            </h3>
            <button
              className={`py-2 px-4 rounded-lg ${
                showModuleForm
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-primary hover:bg-primary-hover"
              } text-white transition-colors`}
              onClick={() => {
                setShowModuleForm(!showModuleForm);
                setEditingModule(null);
              }}
            >
              {showModuleForm ? "Cerrar Formulario" : "Agregar Módulo"}
            </button>
          </div>

          {/* Indicador de Carga de Módulos */}
          {loadingModules ? (
            <div className="flex justify-center py-4">
              <FaSpinner className="animate-spin text-primary h-6 w-6" />
            </div>
          ) : (
            <>
              {modules.length === 0 ? (
                <p className="text-gray-600 text-center">No hay módulos disponibles para este curso.</p>
              ) : (
                <div className="space-y-4">
                  {modules.map((module) => (
                    <div
                      key={module.id}
                      className="flex justify-between items-center p-4 bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow"
                    >
                      <div>
                        <h4 className="text-lg font-semibold text-primary">{module.title}</h4>
                        <p className="text-gray-600">{module.description}</p>
                      </div>
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleEditModule(module)}
                          className="bg-secondary text-white p-2 rounded-lg hover:bg-secondary-hover transition-colors"
                          aria-label={`Editar módulo ${module.title}`}
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleDeleteModule(module.id)}
                          className="bg-danger text-white p-2 rounded-lg hover:bg-danger-hover transition-transform transform hover:scale-105"
                          aria-label={`Eliminar módulo ${module.title}`}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {/* Formulario para Agregar/Editar Módulo */}
          {showModuleForm && (
            <ModuleForm
              onSubmit={handleModuleSubmit}
              initialData={editingModule}
              processing={processing}
              onCancel={() => {
                setShowModuleForm(false);
                setEditingModule(null);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AdminModules;
