import React from 'react';
import HeaderProgram from '../components/HeaderProgram';
import PlanDeEstudio from '../components/PlanDeEstudio';
import BotonEnlace from '../components/BotonEnlace';
import Requisitos from '../components/Requisitos';
import Galeria from '../components/Galeria';
import infanciasImage from '../assets/img/portfolio/fullsize/infancia.jpg'; 
import thumbnailImage1 from '../assets/img/portfolio/thumbnails/1.jpg';
import thumbnailImage2 from '../assets/img/portfolio/thumbnails/2.jpg';
import thumbnailImage3 from '../assets/img/portfolio/thumbnails/3.jpg';
import thumbnailImage4 from '../assets/img/portfolio/thumbnails/4.jpg';
import thumbnailImage5 from '../assets/img/portfolio/thumbnails/5.jpg';
import thumbnailImage6 from '../assets/img/portfolio/thumbnails/6.jpg';
import thumbnailImage7 from '../assets/img/portfolio/thumbnails/7.jpg';


const Infancia = () => {
  const planDeEstudio = [
    { title: 'Fundamentos de la educación inicial', description: '' },
    { title: 'Psicología General', description: '' },
    { title: 'Infancia y Familia', description: '' },
    { title: 'Lenguaje Expresivo', description: '' },
    { title: 'Lineamientos Curriculares', description: '' },
    { title: 'Nutrición y Salud', description: '' },
    { title: 'Juegos y Recreación', description: '' },
    { title: 'Gestión Educativa', description: '' },
    { title: 'Legislación y Política Pública', description: '' },
    { title: 'Literatura Infantil', description: '' },
    { title: 'Proyecto de Vida', description: '' },
    { title: 'Emprendimiento', description: '' },
    { title: 'Tics', description: '' },
    { title: 'Inglés', description: '' },
    { title: 'Ética', description: '' },
    { title: 'P. y P. Pedagógicas', description: '' },
  ];

  const requisitos = [
    { title: 'Edad', description: '16 años o más' },
    { title: 'Escolaridad', description: 'Certificado de grados cursados' },
    { title: 'Copia', description: 'Documento de Identidad' },
    { title: 'Digital', description: '1 foto tamaño 3x4' },
    { title: 'Copia', description: 'Comprobante de Pago' },
  ];

  const imagenes = [
    { src: thumbnailImage1, link: '/teologia', alt: 'Imagen de Galería 1' },
    { src: thumbnailImage4, link: '/administrativo', alt: 'Imagen de Galería 2' },
    { src: thumbnailImage2, link: '/bachillerato', alt: 'Imagen de Galería 3' },
    { src: thumbnailImage5, link: '/musica', alt: 'Imagen de Galería 4' },
    { src: thumbnailImage6, link: '/ingles', alt: 'Imagen de Galería 5' },
    { src: thumbnailImage7, link: '/contable', alt: 'Imagen de Galería 6' },
  ];

  return (
    <div>
     

      {/* Header con Imagen de Fondo Relevante */}
      <HeaderProgram backgroundImage={infanciasImage} />

      {/* Contenido Principal */}
      <main className="container my-5">
        {/* Sección: Plan de Estudio */}
        <PlanDeEstudio title="Plan de Estudio" items={planDeEstudio} />

        {/* Sección: Botón de Costos */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace
              className="btn btn-masinfo btn-xl"
              href="https://wa.me/573216200693?text=Hola,%20quiero%20más%20información%20sobre%20el%20programa%20Técnico%20Laboral%20en%20Atención%20a%20la%20Primera%20Infancia%20y%20sus%20costos"
              target="_blank"
              rel="noopener noreferrer"
              title="Contacta con nosotros en WhatsApp"
            >
              <i className="bi-whatsapp me-2"></i> Costos y más información
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Requisitos */}
        <Requisitos title="Requisitos" requisitos={requisitos} />

        {/* Sección: Botón de Pre-inscripción */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace className="btn btn-primary btn-xl" href="/preinscripcion">
              Pre-inscribirme
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Galería */}
        <Galeria title="Galería" imagenes={imagenes} />
      </main>

      {/* Footer */}
      <footer className="bg-light py-4">
        <div className="container text-center">
          <p className="mb-0">© 2024 Corporación Educativa Pentecostal</p>
        </div>
      </footer>

      {/* Scripts */}
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>
      <script src="/assets/js/scripts.js"></script>
    </div>
  );
};

export default Infancia;
