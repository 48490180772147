export const ciudades = [
    "Abejorral", "Abrego", "Abriaquí", "Acacías", "Acandí", "Acevedo", "Achí", "Agrado", 
    "Agua de Dios", "Aguachica", "Aguada", "Aguadas", "Aguazul", "Agustín Codazzi", "Aipe", 
    "Albán", "Albania", "Alcalá", "Aldana", "Alejandría", "Algarrobo", "Algeciras", "Almaguer", 
    "Almeida", "Alpujarra", "Altamira", "Alto Baudo", "Altos del Rosario", "Alvarado", "Amagá", 
    "Amalfi", "Ambalema", "Anapoima", "Ancuyá", "Andalucía", "Andes", "Angelópolis", "Angostura", 
    "Anolaima", "Anorí", "Anserma", "Ansermanuevo", "Anza", "Anzoátegui", "Apartadó", "Apía", 
    "Apulo", "Aquitania", "Aracataca", "Aranzazu", "Aratoca", "Arauca", "Arauquita", "Arbeláez", 
    "Arboleda", "Arboledas", "Arboletes", "Arcabuco", "Arenal", "Argelia", "Ariguaní", "Arjona", 
    "Armenia", "Armero", "Arroyohondo", "Astrea", "Ataco", "Atrato", "Ayapel", "Bagadó", 
    "Bahía Solano", "Bajo Baudó", "Balboa", "Baranoa", "Baraya", "Barbacoas", "Barbosa", "Barichara", 
    "Barranca de Upía", "Barrancabermeja", "Barrancas", "Barranco de Loba", "Barranquilla", 
    "Becerril", "Belalcázar", "Belén", "Belén de Bajirá", "Belén de Los Andaquies", "Belén de Umbría", 
    "Bello", "Belmira", "Beltrán", "Berbeo", "Betania", "Betéitiva", "Betulia", "Bituima", 
    "Boavita", "Bochalema", "Bogotá", "Bojacá", "Bojaya", "Bolívar", "Bosconia", "Boyacá", 
    "Briceño", "Bucaramanga", "Bucarasica", "Buenaventura", "Buenavista", "Buenos Aires", 
    "Buesaco", "Buga", "Bugalagrande", "Buriticá", "Busbanzá", "Cabrera", "Cabuyaro", 
    "Cacahual", "Cáceres", "Cachipay", "Cachirá", "Cácota", "Caicedo", "Caicedonia", "Caimito", 
    "Cajamarca", "Cajibío", "Cajicá", "Calamar", "Calarca", "Caldas", "Caldono", "Cali", 
    "California", "Calima", "Caloto", "Campamento", "Campo de la Cruz", "Campoalegre", 
    "Campohermoso", "Canalete", "Cañasgordas", "Candelaria", "Cantagallo", "Caparrapí", 
    "Capitanejo", "Caqueza", "Caracolí", "Caramanta", "Carcasí", "Carepa", "Carmen de Apicalá", 
    "Carmen de Carupa", "Carmen del Darien", "Carmen del Viboral", "Carolina", "Cartagena", 
    "Cartagena del Chairá", "Cartago", "Caruru", "Casabianca", "Castilla La Nueva", "Caucasia", 
    "Cepitá", "Cereté", "Cerinza", "Cerrito", "Cerro San Antonio", "Cértegui", "Chachagüí", 
    "Chaguaní", "Chalán", "Chameza", "Chaparral", "Charalá", "Charta", "Chía", "Chibolo", 
    "Chigorodó", "Chima", "Chimá", "Chimichagua", "Chinácota", "Chinavita", "Chinchiná", 
    "Chinú", "Chipaque", "Chipatá", "Chiquinquirá", "Chíquiza", "Chiriguaná", "Chiscas", 
    "Chita", "Chitagá", "Chitaraque", "Chivatá", "Chivor", "Choachí", "Chocontá", "Cicuco", 
    "Ciénaga", "Ciénaga de Oro", "Ciénega", "Cimitarra", "Circasia", "Cisneros", "Ciudad Bolívar", 
    "Clemencia", "Cocorná", "Coello", "Cogua", "Colombia", "Colón", "Coloso", "Cómbita", 
    "Concepción", "Concordia", "Condoto", "Confines", "Consaca", "Contadero", "Contratación", 
    "Convención", "Copacabana", "Córdoba", "Corinto", "Corozal", "Corrales", "Cota", 
    "Cotorra", "Covarachía", "Coveñas", "Coyaima", "Cravo Norte", "Cuaspud", "Cubará", 
    "Cubarral", "Cucaita", "Cucunubá", "Cúcuta", "Cucutilla", "Cuítiva", "Cumaral", 
    "Cumaribo", "Cumbal", "Cumbitara", "Cunday", "Curillo", "Curití", "Curumaní", "Dabeiba", 
    "Dagua", "Dibulla", "Distracción", "Dolores", "Don Matías", "Dosquebradas", "Duitama", 
    "Durania", "Ebéjico", "El Águila", "El Bagre", "El Banco", "El Cairo", "El Calvario", 
    "El Cantón del San Pablo", "El Carmen", "El Carmen de Atrato", "El Carmen de Bolívar", 
    "El Carmen de Chucurí", "El Carmen de Viboral", "El Castillo", "El Cerrito", "El Charco", 
    "El Cocuy", "El Colegio", "El Copey", "El Doncello", "El Dorado", "El Dovio", "El encanto", 
    "El Espino", "El Guacamayo", "El Guamo", "El Litoral del San Juan", "El Molino", "El Paso", 
    "El Paujil", "El Peñol", "El Piñon", "El Playón", "El Retén", "El Retorno", "El Roble", 
    "El Rosal", "El Rosario", "El Santuario", "El Tablón de Gómez", "El Tambo", "El Tarra", 
    "El Zulia", "Elías", "Encino", "Enciso", "Entrerrios", "Envigado", "Espinal", "Facatativá", 
    "Falan", "Filadelfia", "Filandia", "Firavitoba", "Flandes", "Florencia", "Floresta", 
    "Florián", "Florida", "Floridablanca", "Fomeque", "Fonseca", "Fortul", "Fosca", 
    "Francisco Pizarro", "Fredonia", "Fresno", "Frontino", "Fuente de Oro", "Fundación", 
    "Funes", "Funza", "Fúquene", "Fusagasugá", "Gachala", "Gachancipá", "Gachantivá", 
    "Gachetá", "Galán", "Galapa", "Galeras", "Gama", "Gamarra", "Gambita", "Gameza", 
    "Garagoa", "Garzón", "Génova", "Gigante", "Ginebra", "Giraldo", "Girardot", "Girardota", 
    "Girón", "Gómez Plata", "González", "Gramalote", "Granada", "Guaca", "Guacamayas", 
    "Guacarí", "Guachetá", "Guachucal", "Guadalupe", "Guaduas", "Guaitarilla", "Gualmatán", 
    "Guamal", "Guamo", "Guapi", "Guapotá", "Guaranda", "Guarne", "Guasca", "Guatape", 
    "Guataquí", "Guatavita", "Guateque", "Guática", "Guavatá", "Guayabal de Siquima", 
    "Guayabetal", "Guayatá", "Güepsa", "Güicán", "Gutiérrez", "Hacarí", "Hatillo de Loba", 
    "Hato", "Hato Corozal", "Hatonuevo", "Heliconia", "Herrán", "Herveo", "Hispania", 
    "Hobo", "Honda", "Ibagué", "Icononzo", "Iles", "Imués", "Inírida", "Inzá", "Ipiales", 
    "Iquira", "Isnos", "Istmina", "Itagui", "Ituango", "Iza", "Jambaló", "Jamundí", "Jardín", 
    "Jenesano", "Jericó", "Jerusalén", "Jesús María", "Jordán", "Juan de Acosta", "Junín", 
    "Juradó", "La Apartada", "La Argentina", "La Belleza", "La Calera", "La Capilla", 
    "La Ceja", "La Celia", "La chorrera", "La Cruz", "La Cumbre", "La Dorada", "La Esperanza", 
    "La Estrella", "La Florida", "La Gloria", "La Guadalupe", "La Jagua de Ibirico", 
    "La Jagua del Pilar", "La Llanada", "La Macarena", "La Merced", "La Mesa", "La Montañita", 
    "La Palma", "La Paz", "La pedrera", "La Peña", "La Pintada", "La Plata", "La Playa", 
    "La Primavera", "La Salina", "La Sierra", "La Tebaida", "La Tola", "La Unión", "La Uvita", 
    "La Vega", "La Victoria", "La Virginia", "Labateca", "Labranzagrande", "Landázuri", 
    "Lebríja", "Leguízamo", "Leiva", "Lejanías", "Lenguazaque", "Lérida", "Leticia", 
    "Líbano", "Liborina", "Linares", "Lloró", "López", "Lorica", "Los Andes", "Los Córdobas", 
    "Los Palmitos", "Los Patios", "Los Santos", "Lourdes", "Luruaco", "Macanal", "Macaravita", 
    "Maceo", "Macheta", "Madrid", "Magangué", "Magüi", "Mahates", "Maicao", "Majagual", 
    "Málaga", "Malambo", "Mallama", "Manatí", "Manaure", "Maní", "Manizales", "Manta", 
    "Manzanares", "Mapiripán", "Mapiripana", "Margarita", "María La Baja", "Marinilla", 
    "Maripí", "Mariquita", "Marmato", "Marquetalia", "Marsella", "Marulanda", "Matanza", 
    "Medellín", "Medina", "Medio Atrato", "Medio Baudó", "Medio San Juan", "Melgar", 
    "Mercaderes", "Mesetas", "Milán", "Miraflores", "Miranda", "Mitú", "Mocoa", "Mogotes", 
    "Molagavita", "Momil", "Mompós", "Mongua", "Monguí", "Moniquirá", "Moñitos", "Montebello", 
    "Montecristo", "Montelíbano", "Montenegro", "Montería", "Monterrey", "Morales", "Morelia", 
    "Morichal", "Morroa", "Mosquera", "Motavita", "Murillo", "Murindó", "Mutatá", "Mutiscua", 
    "Muzo", "Nariño", "Nátaga", "Natagaima", "Nechí", "Necoclí", "Neira", "Neiva", "Nemocón", 
    "Nilo", "Nimaima", "Nobsa", "Nocaima", "Norcasia", "Nóvita", "Nueva Granada", "Nuevo Colón", 
    "Nunchía", "Nuquí", "Obando", "Ocamonte", "Ocaña", "Oiba", "Oicatá", "Olaya", "Onzaga", 
    "Oporapa", "Orito", "Orocué", "Ortega", "Ospina", "Otanche", "Ovejas", "Pachavita", 
    "Pacho", "Pacoa", "Pácora", "Padilla", "Paez", "Paicol", "Pailitas", "Paime", "Paipa", 
    "Pajarito", "Palermo", "Palestina", "Palmar", "Palmar de Varela", "Palmas del Socorro", 
    "Palmira", "Palmito", "Palocabildo", "Pamplona", "Pamplonita", "Pana Pana", "Pandi", 
    "Panqueba", "Papunaua", "Páramo", "Paratebueno", "Pasca", "Pasto", "Patía", "Pauna", 
    "Paya", "Paz de Ariporo", "Paz de Río", "Pedraza", "Pelaya", "Peñol", "Pensilvania", 
    "Peque", "Pereira", "Pesca", "Piamonte", "Piedecuesta", "Piedras", "Piendamó", "Pijao", 
    "Pijiño del Carmen", "Pinchote", "Pinillos", "Piojó", "Pisba", "Pital", "Pitalito", 
    "Pivijay", "Planadas", "Planeta Rica", "Plato", "Policarpa", "Polonuevo", "Ponedera", 
    "Popayán", "Pore", "Potosí", "Pradera", "Prado", "Providencia", "Pueblo Bello", 
    "Pueblo Nuevo", "Pueblorrico", "Puebloviejo", "Puente Nacional", "Puerres", "Puerto Alegría", 
    "Puerto Arica", "Puerto Asís", "Puerto Berrío", "Puerto Boyacá", "Puerto Caicedo", 
    "Puerto Carreño", "Puerto Colombia", "Puerto Concordia", "Puerto Escondido", "Puerto Gaitán", 
    "Puerto Guzmán", "Puerto Libertador", "Puerto Lleras", "Puerto López", "Puerto Nare", 
    "Puerto Nariño", "Puerto Parra", "Puerto Rico", "Puerto Rondón", "Puerto Salgar", 
    "Puerto Santander", "Puerto Tejada", "Puerto Triunfo", "Puerto Wilches", "Pulí", 
    "Pupiales", "Puracé", "Purificación", "Purísima", "Quebradanegra", "Quetame", "Quibdó", 
    "Quimbaya", "Quinchía", "Quípama", "Quipile", "Ragonvalia", "Ramiriquí", "Ráquira", 
    "Recetor", "Regidor", "Remedios", "Remolino", "Repelón", "Restrepo", "Retiro", 
    "Ricaurte", "Río de Oro", "Río Iro", "Río Quito", "Río Viejo", "Rioblanco", "Riofrío", 
    "Riohacha", "Rionegro", "Riosucio", "Risaralda", "Rivera", "Roberto Payán", "Roldanillo", 
    "Roncesvalles", "Rondón", "Rosas", "Rovira", "Sabana de Torres", "Sabanagrande", 
    "Sabanalarga", "Sabanas de San Angel", "Sabaneta", "Saboyá", "Sácama", "Sáchica", 
    "Sahagún", "Saladoblanco", "Salamina", "Salazar", "Saldaña", "Salento", "Salgar", 
    "Samacá", "Samaná", "Samaniego", "Sampués", "San Agustín", "San Alberto", "San Andrés", 
    "San Antero", "San Antonio", "San Benito", "San Bernardo", "San Calixto", "San Carlos", 
    "San Cayetano", "San Cristóbal", "San Diego", "San Eduardo", "San Estanislao", 
    "San Felipe", "San Fernando", "San Francisco", "San Gil", "San Jacinto", "San Jerónimo", 
    "San Joaquín", "San José", "San José de la Montaña", "San José de Miranda", 
    "San José del Fragua", "San Juan de Betulia", "San Juan del Cesar", "San Juan Nepomuceno", 
    "San Luis", "San Marcos", "San Martín", "San Mateo", "San Miguel", "San Pablo", 
    "San Pedro", "San Pelayo", "San Rafael", "San Roque", "San Sebastián", "San Vicente", 
    "San Zenón", "Santa Ana", "Santa Bárbara", "Santa Catalina", "Santa Isabel", "Santa Lucía", 
    "Santa María", "Santa Marta", "Santa Rosa", "Santa Rosa de Cabal", "Santa Rosa de Osos", 
    "Santa Rosa del Sur", "Santa Rosalía", "Santacruz", "Santafé de Antioquia", "Santana", 
    "Santander de Quilichao", "Santiago", "Santo Domingo", "Santuario", "Sapuyes", 
    "Saravena", "Sardinata", "Sasaima", "Segovia", "Sesquilé", "Sevilla", "Siachoque", 
    "Sibaté", "Silos", "Silvania", "Silvia", "Simacota", "Simijaca", "Simití", "Sincé", 
    "Sincelejo", "Sipí", "Sitionuevo", "Soacha", "Soatá", "Socha", "Socorro", "Socotá", 
    "Sogamoso", "Solano", "Soledad", "Solita", "Somondoco", "Sonson", "Sopetrán", 
    "Soplaviento", "Sopó", "Sora", "Soracá", "Sotaquirá", "Sutamarchán", "Sutatausa", 
    "Tabio", "Tadó", "Talaigua Nuevo", "Tamalameque", "Támara", "Tame", "Támesis", 
    "Taminango", "Tangua", "Tarapacá", "Tarazá", "Tarqui", "Tarso", "Tasco", "Tauramena", 
    "Tausa", "Tello", "Tena", "Tenerife", "Tenjo", "Tenza", "Teorama", "Teruel", 
    "Tesalia", "Tibacuy", "Tibaná", "Tibasosa", "Tibú", "Tierralta", "Timaná", "Timbío", 
    "Timbiquí", "Tinjacá", "Tipacoque", "Tiquisio", "Titiribí", "Toca", "Tocaima", 
    "Tocancipá", "Togüí", "Toledo", "Tolú Viejo", "Tona", "Tópaga", "Topaipí", "Toribio", 
    "Toro", "Tota", "Totoró", "Trinidad", "Trujillo", "Tubará", "Tuluá", "Tumaco", 
    "Tunja", "Tununguá", "Túquerres", "Turbaco", "Turbo", "Turmequé", "Tuta", "Tutazá", 
    "Ubalá", "Ubaque", "Ulloa", "Umbita", "Une", "Unguía", "Unión Panamericana", 
    "Uramita", "Uribe", "Uribia", "Urrao", "Urumita", "Usiacurí", "Útica", "Valdivia", 
    "Valencia", "Valle de San José", "Valledupar", "Vegachí", "Vélez", "Venadillo", 
    "Venecia", "Ventaquemada", "Vergara", "Versalles", "Vetas", "Vianí", "Victoria", 
    "Vigía del Fuerte", "Vijes", "Villa Caro", "Villa de Leyva", "Villa del Rosario", 
    "Villagarzón", "Villagómez", "Villamaría", "Villanueva", "Villapinzón", "Villavicencio", 
    "Villeta", "Viotá", "Viracachá", "Vistahermosa", "Viterbo", "Yacopí", "Yacuanquer", 
    "Yaguará", "Yalí", "Yarumal", "Yavaraté", "Yolombó", "Yondó", "Yopal", "Yotoco", 
    "Yumbo", "Zambrano", "Zapatoca", "Zapayán", "Zaragoza", "Zarzal", "Zetaquira", 
    "Zipacón", "Zipaquirá", "Zona Bananera"
  ];
  
  