import React, { useState, useEffect } from 'react';

const CourseForm = ({ onSubmit, editingCourse, setEditingCourse, setMessage, setError }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (editingCourse) {
      setTitle(editingCourse.title);
      setDescription(editingCourse.description);
      setImageUrl(editingCourse.imageUrl);
    } else {
      setTitle('');
      setDescription('');
      setImageUrl('');
    }
  }, [editingCourse]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.trim() && description.trim() && imageUrl.trim()) {
      onSubmit({ title, description, imageUrl });
      setTitle('');
      setDescription('');
      setImageUrl('');
      setMessage('');
      setError('');
    } else {
      setMessage('Por favor, completa todos los campos del curso.');
    }
  };

  const handleCancel = () => {
    setEditingCourse(null);
    setTitle('');
    setDescription('');
    setImageUrl('');
    setMessage('');
    setError('');
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="courseTitle" className="block text-sm font-medium text-gray-700">
          Título del Curso
        </label>
        <input
          id="courseTitle"
          type="text"
          placeholder="Título del Curso"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          required
        />
      </div>

      <div>
        <label htmlFor="courseDescription" className="block text-sm font-medium text-gray-700">
          Descripción del Curso
        </label>
        <textarea
          id="courseDescription"
          placeholder="Descripción del Curso"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          required
        />
      </div>

      <div>
        <label htmlFor="courseImage" className="block text-sm font-medium text-gray-700">
          URL de la Imagen
        </label>
        <input
          id="courseImage"
          type="url"
          placeholder="URL de la Imagen"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
          required
        />
      </div>

      <div className="flex space-x-4">
        <button type="submit" className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors">
          {editingCourse ? 'Actualizar Curso' : 'Agregar Curso'}
        </button>
        {editingCourse && (
          <button
            type="button"
            onClick={handleCancel}
            className="bg-secondary text-white py-2 px-4 rounded-lg hover:bg-secondary-hover transition-colors"
          >
            Cancelar
          </button>
        )}
      </div>
    </form>
  );
};

export default CourseForm;
