import React from 'react';

const BotonEnlace = ({ href, className, children, target, rel, title }) => {
  return (
    <a
      className={className}
      href={href}
      target={target}
      rel={rel}
      title={title}
    >
      {children}
    </a>
  );
};

export default BotonEnlace;
