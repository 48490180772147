// src/components/Lesson/Lesson.jsx

import React, { useState } from 'react';
import Question from './Question';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

const Lesson = ({ lesson, handleAnswerSubmit }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    lesson?.questions?.map(() => null) || []
  );
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  if (!lesson) {
    return (
      <div className="text-center text-red-600 font-poppins">
        <h2 className="text-2xl font-semibold">Lección no encontrada</h2>
      </div>
    );
  }

  // Manejar cambios en las opciones seleccionadas
  const handleOptionChange = (qIndex, value) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[qIndex] = value ? value.trim() : ''; // Eliminar espacios y manejar valores nulos
    setSelectedOptions(updatedOptions);
  };

  // Manejar el envío de respuestas
  const onSubmit = () => {
    if (selectedOptions.includes(null)) {
      setFeedbackMessage({
        type: 'error',
        text: 'Por favor, responde todas las preguntas.',
      });
      return;
    }

    // Validar si todas las respuestas son correctas
    const allCorrect = lesson.questions.every((q, index) => {
      const correctAnswer = q.answer ? q.answer.trim().toLowerCase() : ''; // Verificar que `answer` esté definido
      const selectedAnswer = selectedOptions[index]
        ? selectedOptions[index].trim().toLowerCase()
        : '';
      return correctAnswer === selectedAnswer;
    });

    if (allCorrect) {
      // Respuestas correctas, permitir avance
      handleAnswerSubmit(selectedOptions, null);
      setFeedbackMessage({
        type: 'success',
        text: '¡Lección completada correctamente!',
      });
    } else {
      // Alguna respuesta incorrecta, impedir avance
      setFeedbackMessage({
        type: 'error',
        text: 'Algunas respuestas son incorrectas. Vuelve a ver el video e intenta responder nuevamente.',
      });
    }
  };

  return (
    <div className="bg-card-background p-6 rounded-lg shadow-md mb-8 max-w-4xl mx-auto font-poppins">
      <h2 className="text-2xl text-primary mb-5">{lesson.title}</h2>
      {lesson.videoUrl ? (
        <div className="mb-5 rounded-lg overflow-hidden">
          <ReactPlayer
            url={lesson.videoUrl}
            width="100%"
            height="450px"
            controls={true}
          />
        </div>
      ) : (
        <div className="text-center text-red-600 mb-5">
          <h3 className="text-xl font-semibold">No hay video disponible para esta lección.</h3>
        </div>
      )}
      <p className="text-base text-secondary-text-color mb-8">{lesson.description}</p>

      <div className="mt-8">
        <h3 className="text-xl text-primary mb-4">Preguntas de la Lección:</h3>
        {lesson.questions?.length > 0 ? (
          lesson.questions.map((q, index) => (
            <Question
              key={index}
              question={q.question}
              options={q.options}
              selectedOption={selectedOptions[index]}
              handleChange={(e) => handleOptionChange(index, e.target.value)}
            />
          ))
        ) : (
          <div className="text-center text-red-600">
            <h4 className="text-lg font-semibold">No hay preguntas disponibles para esta lección.</h4>
          </div>
        )}
        <button
          onClick={onSubmit}
          className="mt-5 w-full bg-primary text-white py-3 px-6 rounded-lg hover:bg-primary-hover transition-colors duration-300 font-semibold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
        >
          Completar Lección
        </button>
        {feedbackMessage && (
          <div
            className={`mt-4 p-4 rounded-lg ${
              feedbackMessage.type === 'success'
                ? 'bg-green-100 text-green-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            {feedbackMessage.text}
          </div>
        )}
      </div>
    </div>
  );
};

// Definición de PropTypes para mejor mantenimiento y debugging
Lesson.propTypes = {
  lesson: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    videoUrl: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.string).isRequired,
        answer: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
};

export default Lesson;
