// src/firebase/auth.js
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from 'firebase/auth';

// Login function
export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

// Register function
export const register = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

// Logout function
export const logout = () => {
  return signOut(auth);
};

// Función para iniciar sesión como administrador
export const signInAdmin = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };