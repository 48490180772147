import React from 'react';
import icon from '../assets/icon.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Header = () => {
  return (
    <nav id="mainNav" className="navbar navbar-expand-lg navbar-light fixed-top py-1">
      <div className="container px-4 px-lg-0">
        {/* Enlace a la página principal */}
        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img
            src={icon}
            alt="Logo"
            className="navbar-logo me-2"
            style={{ width: '100px', height: 'auto' }}
          />
          <span>
            Corporación Educativa <br /> Pentecostal
          </span>
        </Link>
        
        {/* Botón de colapso para dispositivos móviles */}
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        {/* Menú de navegación */}
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto my-2 my-lg-0">
            {/* Enlace a la página Bachillerato */}
            <li className="nav-item">
            <HashLink className="nav-link" to="/#bachillerato">Bachillerato</HashLink>
            </li>
            
            {/* Enlaces a secciones dentro de la página principal */}
            <li className="nav-item">
              <HashLink smooth className="nav-link" to="/#portfolio">Técnicos</HashLink>
            </li>
            <li className="nav-item">
              <HashLink smooth className="nav-link" to="/#download">Descargas</HashLink>
            </li>
            <li className="nav-item">
              <HashLink smooth className="nav-link" to="/#contact">Contacto</HashLink>
            </li>
            
            {/* Enlace externo */}
            <li className="nav-item">
              <a
                className="btn btn-primary ms-3"
                href="/campus"
                rel="noopener noreferrer"
              >
                Campus Virtual
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;


