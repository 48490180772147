// src/components/AdminLessons/AdminLessons.jsx

import React, { useState, useEffect } from "react";
import {
  getCourses
} from "../../firebase/firestore/courses"; 
import {
  getCourseModules
} from "../../firebase/firestore/modules"; 
import {
  addLessonToModule,
  getModuleLessons,
  updateLesson,
  deleteLesson,
} from "../../firebase/firestore/lessons";
import LessonForm from "./LessonForm";
import LessonItem from "./LessonItem";
import CourseSelector from "../AdminModules/CourseSelector"; // Importar el componente CourseSelector
import { FaPlus, FaSpinner } from "react-icons/fa";

const AdminLessons = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [editingLesson, setEditingLesson] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showLessonForm, setShowLessonForm] = useState(false); // Definir showLessonForm y setShowLessonForm

  useEffect(() => {
    fetchCourses();
  }, []);

  // Función para obtener todos los cursos
  const fetchCourses = async () => {
    setLoadingCourses(true);
    try {
      const courseData = await getCourses();
      // Ordenar los cursos de la A a la Z
      const sortedCourses = courseData.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setCourses(sortedCourses);
    } catch (error) {
      setError("Error al cargar los cursos.");
      console.error(error);
    } finally {
      setLoadingCourses(false);
    }
  };

  // Función para manejar la selección de un curso desde CourseSelector
  const handleCourseSelect = async (courseId) => {
    const course = courses.find((c) => c.id === courseId);
    setSelectedCourse(course);
    setSelectedModule(null);
    setLessons([]);
    setMessage("");
    setError("");
    setEditingLesson(null);
    setShowLessonForm(false); // Resetear el formulario al cambiar de curso

    if (!courseId) return;

    setLoadingModules(true);
    try {
      const modulesData = await getCourseModules(courseId);
      // Ordenar los módulos de la A a la Z
      const sortedModules = modulesData.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setModules(sortedModules);
    } catch (error) {
      setError("Error al cargar los módulos del curso.");
      console.error(error);
    } finally {
      setLoadingModules(false);
    }
  };

  // Función para manejar la selección de un módulo
  const handleModuleSelect = async (moduleId) => {
    const module = modules.find((m) => m.id === moduleId);
    setSelectedModule(module);
    setLessons([]);
    setMessage("");
    setError("");
    setEditingLesson(null);
    setShowLessonForm(false); // Resetear el formulario al cambiar de módulo

    if (!moduleId) return;

    setProcessing(true);
    try {
      const lessonsData = await getModuleLessons(selectedCourse.id, moduleId);
      // Ordenar las lecciones de la A a la Z
      const sortedLessons = lessonsData.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setLessons(sortedLessons);
    } catch (error) {
      setError("Error al cargar las lecciones del módulo.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // Función para manejar la creación de una lección
  const handleAddLesson = async (lessonData) => {
    try {
      if (!selectedCourse || !selectedModule) {
        setError("Por favor, selecciona un curso y un módulo.");
        return;
      }
      setProcessing(true);
      await addLessonToModule(selectedCourse.id, selectedModule.id, lessonData);
      setMessage("Lección agregada exitosamente.");
      fetchLessons();
    } catch (error) {
      setError("Error al agregar la lección.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // Función para manejar la actualización de una lección
  const handleUpdateLesson = async (lessonId, lessonData) => {
    try {
      if (!selectedCourse || !selectedModule) {
        setError("Por favor, selecciona un curso y un módulo.");
        return;
      }
      setProcessing(true);
      await updateLesson(selectedCourse.id, selectedModule.id, lessonId, lessonData);
      setMessage("Lección actualizada exitosamente.");
      setEditingLesson(null);
      fetchLessons();
    } catch (error) {
      setError("Error al actualizar la lección.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // Función para refrescar las lecciones del módulo seleccionado
  const fetchLessons = async () => {
    if (!selectedCourse || !selectedModule) return;
    setProcessing(true);
    try {
      const lessonsData = await getModuleLessons(
        selectedCourse.id,
        selectedModule.id
      );
      // Ordenar las lecciones de la A a la Z
      const sortedLessons = lessonsData.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setLessons(sortedLessons);
    } catch (error) {
      setError("Error al cargar las lecciones.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  // Función para manejar la eliminación de una lección
  const handleDeleteLesson = async (lessonId) => {
    if (
      window.confirm("¿Estás seguro de que deseas eliminar esta lección?")
    ) {
      try {
        setProcessing(true);
        await deleteLesson(selectedCourse.id, selectedModule.id, lessonId);
        setMessage("Lección eliminada exitosamente.");
        fetchLessons();
      } catch (error) {
        setError("Error al eliminar la lección.");
        console.error(error);
      } finally {
        setProcessing(false);
      }
    }
  };

  // Función para manejar la preparación para editar una lección
  const handleEditLesson = (lesson) => {
    setEditingLesson(lesson);
    setShowLessonForm(true); // Mostrar el formulario al editar
  };

  return (
    <div className="max-w-7xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">Administrar Lecciones</h2>

      {/* Alertas de Mensajes */}
      {message && (
        <div className="bg-green-100 text-green-800 p-4 rounded-lg mb-6">
          {message}
        </div>
      )}
      {error && (
        <div className="bg-red-100 text-red-800 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Selección de Curso */}
      <div className="bg-gray-50 p-6 mb-6 rounded-lg shadow-md">
        <CourseSelector 
          courses={courses} 
          onSelect={handleCourseSelect} 
          loading={loadingCourses} 
        />
      </div>

      {/* Lista de Módulos */}
      {selectedCourse && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
          <h3 className="text-xl font-semibold text-secondary mb-4">Módulos de {selectedCourse.title}</h3>
          {loadingModules ? (
            <div className="flex justify-center items-center">
              <FaSpinner className="animate-spin text-primary h-6 w-6" />
            </div>
          ) : (
            <ul className="list-none p-0">
              {modules.map((module) => (
                <li
                  key={module.id}
                  className={`p-3 border rounded-md mb-2 cursor-pointer transition-colors ${
                    selectedModule && selectedModule.id === module.id
                      ? "bg-primary text-white"
                      : "bg-white hover:bg-gray-100"
                  }`}
                  onClick={() => handleModuleSelect(module.id)}
                >
                  {module.title}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Formulario para Agregar o Editar Lecciones */}
      {selectedModule && showLessonForm && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
          <LessonForm
            courses={courses}
            selectedCourse={selectedCourse}
            onCourseChange={handleCourseSelect}
            onAddLesson={handleAddLesson}
            onUpdateLesson={handleUpdateLesson}
            editingLesson={editingLesson}
            setMessage={setMessage}
            setEditingLesson={setEditingLesson}
            processing={processing}
          />
        </div>
      )}

      {/* Botón para Agregar Lección */}
      {selectedModule && !showLessonForm && (
        <div className="mb-6">
          <button
            className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors"
            onClick={() => {
              setShowLessonForm(true);
              setEditingLesson(null);
            }}
          >
            <FaPlus />
            Agregar Lección
          </button>
        </div>
      )}

      {/* Lista de Lecciones */}
      {selectedModule && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-secondary mb-4">Lecciones de {selectedModule.title}</h3>
          {processing ? (
            <div className="flex justify-center items-center">
              <FaSpinner className="animate-spin text-primary h-6 w-6" />
            </div>
          ) : (
            <>
              {lessons.length === 0 ? (
                <p className="text-gray-600">No hay lecciones disponibles en este módulo.</p>
              ) : (
                <ul className="space-y-4">
                  {lessons.map((lesson) => (
                    <LessonItem
                      key={lesson.id}
                      lesson={lesson}
                      onUpdate={() => handleEditLesson(lesson)}
                      onDelete={() => handleDeleteLesson(lesson.id)}
                    />
                  ))}
                </ul>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminLessons;
