import React from 'react';

const Galeria = ({ title, imagenes }) => {
  return (
    <section className="my-5">
      <h2 className="text-center">{title}</h2>
      <div className="row">
        {imagenes.map((img, index) => (
          <div key={index} className="col-lg-4 col-md-6 mb-4">
            <a href={img.link}>
              <img src={img.src} className="img-fluid" alt={img.alt} />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Galeria;
