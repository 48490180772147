// src/components/AdminLessons/LessonItem.jsx

import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';

const LessonItem = ({ lesson, onUpdate, onDelete }) => {
  return (
    <li className="flex justify-between items-center p-4 border border-gray-200 rounded-lg bg-white hover:bg-gray-50 transition-colors">
      <div className="flex items-center space-x-4">
        {/* Video de la Lección */}
        <iframe
          width="200"
          height="113"
          src={lesson.videoUrl.replace('watch?v=', 'embed/')}
          title={`Video de ${lesson.title}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-32 h-20 rounded-lg"
        ></iframe>
        {/* Detalles de la Lección */}
        <div>
          <h4 className="text-lg font-semibold text-primary">{lesson.title}</h4>
          <p className="text-gray-600">{lesson.description}</p>
        </div>
      </div>
      <div className="flex space-x-3">
        <button
          onClick={() => onUpdate(lesson)}
          className="text-secondary hover:text-secondary-hover transition-colors"
          aria-label={`Editar ${lesson.title}`}
        >
          <FaEdit size={20} />
        </button>
        <button
          onClick={() => onDelete(lesson.id)}
          className="text-red-500 hover:text-red-600 transition-colors"
          aria-label={`Eliminar ${lesson.title}`}
        >
          <FaTrash size={20} />
        </button>
      </div>
    </li>
  );
};

export default LessonItem;
