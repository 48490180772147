// src/hooks/useStudentAuth.js
import { useContext, createContext, useState, useEffect } from 'react';

const StudentAuthContext = createContext();

export function StudentAuthProvider({ children }) {
  const [studentUser, setStudentUser] = useState(() => {
    // Intentar recuperar el usuario del localStorage al inicializar
    const storedUser = localStorage.getItem('studentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    // Actualizar el localStorage si el usuario cambia
    if (studentUser) {
      localStorage.setItem('studentUser', JSON.stringify(studentUser));
    } else {
      localStorage.removeItem('studentUser');
    }
  }, [studentUser]);

  return (
    <StudentAuthContext.Provider value={{ studentUser, setStudentUser }}>
      {children}
    </StudentAuthContext.Provider>
  );
}

export const useStudentAuth = () => {
  return useContext(StudentAuthContext);
};
