// src/components/Login/Login.jsx
import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebaseConfig';
import { useStudentAuth } from '../../hooks/useStudentAuth';
import { FaSpinner } from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setStudentUser } = useStudentAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      const studentsCollectionRef = collection(db, 'students');
      const q = query(
        studentsCollectionRef,
        where('email', '==', email),
        where('accessCode', '==', accessCode)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log('Inicio de sesión exitoso');
        const studentData = { email };
        localStorage.setItem('studentUser', JSON.stringify(studentData));
        setStudentUser(studentData);
        navigate('/campus');
      } else {
        setError('Correo electrónico o código de acceso incorrecto.');
      }
    } catch (err) {
      console.error('Error al iniciar sesión:', err);
      setError('Error al iniciar sesión. Por favor, inténtalo de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-primary to-secondary p-6">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Iniciar Sesión</h2>
        <form onSubmit={handleLogin} className="space-y-6">
          <div className="flex flex-col">
            <label htmlFor="email" className="text-gray-700 font-semibold mb-2">Correo Electrónico</label>
            <input
              id="email"
              type="email"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              required
              aria-invalid={error ? "true" : "false"}
              aria-describedby={error ? "email-error" : undefined}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="accessCode" className="text-gray-700 font-semibold mb-2">Código de Acceso</label>
            <input
              id="accessCode"
              type="text"
              placeholder="Código de Acceso"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
              required
              aria-invalid={error ? "true" : "false"}
              aria-describedby={error ? "accessCode-error" : undefined}
            />
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-primary text-white font-bold rounded-lg hover:bg-primary-hover transition-transform transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            disabled={loading}
          >
            {loading ? <FaSpinner className="animate-spin mx-auto" /> : 'Ingresar'}
          </button>
          {error && (
            <p id="error-message" className="text-red-600 font-bold text-center mt-4 animate-fadeIn">
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
