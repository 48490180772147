import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes, FaSpinner } from 'react-icons/fa';

const ModuleForm = ({ onSubmit, initialData, processing, onCancel }) => {
  const [title, setTitle] = useState(initialData ? initialData.title : '');
  const [description, setDescription] = useState(initialData ? initialData.description : '');
  const [errors, setErrors] = useState({ title: '', description: '' });

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.title);
      setDescription(initialData.description);
    }
  }, [initialData]);

  const validate = () => {
    let tempErrors = { title: '', description: '' };
    tempErrors.title = title ? '' : 'El título es requerido.';
    tempErrors.description = description ? '' : 'La descripción es requerida.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      onSubmit({ title, description });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg mt-6">
      <h3 className="text-xl font-bold mb-4">
        {initialData ? 'Editar Módulo' : 'Agregar Módulo'}
      </h3>
      <div className="mb-4">
        <label htmlFor="module-title" className="block text-gray-700 font-semibold mb-2">
          Título del Módulo
        </label>
        <input
          id="module-title"
          type="text"
          className={`w-full p-3 border ${
            errors.title ? 'border-red-500' : 'border-gray-300'
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
      </div>
      <div className="mb-4">
        <label htmlFor="module-description" className="block text-gray-700 font-semibold mb-2">
          Descripción del Módulo
        </label>
        <textarea
          id="module-description"
          rows="4"
          className={`w-full p-3 border ${
            errors.description ? 'border-red-500' : 'border-gray-300'
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
      </div>
      <div className="flex gap-4 mt-6">
        <button
          type="submit"
          className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors"
          disabled={processing}
        >
          {processing ? (
            <span className="flex items-center gap-2">
              <FaSpinner className="animate-spin" /> Guardando...
            </span>
          ) : (
            <span className="flex items-center gap-2">
              <FaSave /> Guardar
            </span>
          )}
        </button>
        <button
          type="button"
          className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-colors"
          onClick={onCancel}
          disabled={processing}
        >
          <span className="flex items-center gap-2">
            <FaTimes /> Cancelar
          </span>
        </button>
      </div>
    </form>
  );
};

export default ModuleForm;
