export const programas = [
    "Bachillerato para Adultos",
    "Técnico Laboral en Atención a la Primera Infancia",
    "Técnico Laboral en Auxiliar Administrativo",
    "Técnico Laboral en Auxiliar Contable",
    "Conocimientos Académicos en Teología",
    "Conocimientos Académicos en Música",
    "Conocimientos Académicos en Inglés",
    // Agrega más programas según sea necesario
  ];
  