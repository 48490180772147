import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAhDdvcwS65ytsTjsN5AJfNSdzthkjvJAM",
    authDomain: "cep-educativa-pentecostal.firebaseapp.com",
    projectId: "cep-educativa-pentecostal",
    storageBucket: "cep-educativa-pentecostal.appspot.com",
    messagingSenderId: "652802702358",
    appId: "1:652802702358:web:d74550a993bc8f84378937",
    measurementId: "G-GFB7G2GV7L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);

