import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminLogin from './components/AdminLogin/AdminLogin';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import Login from './components/Login/Login';
import ModuleDetail from './components/ModuleDetail/ModuleDetail';
import StudentDashboard from './pages/StudentDashboard';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { AdminAuthProvider } from './hooks/useAdminAuth';
import { StudentAuthProvider } from './hooks/useStudentAuth';
import ProtectedRoute from './components/ProtectedRoute';
import './index.css';

function App() {
  return (
    <AdminAuthProvider>
      <StudentAuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<StudentDashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/course/:courseId/module/:moduleId" element={<ModuleDetail />} />
          <Route path="/admin" element={<AdminLogin />} />
          
          {/* Ruta protegida para el dashboard de administrador */}
          <Route
            path="/admin/dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </StudentAuthProvider>
    </AdminAuthProvider>
  );
}

export default App;

