// src/components/CourseHeader/CourseHeader.jsx

import React from 'react';
import PropTypes from 'prop-types';

const CourseHeader = ({ title, description }) => (
  <div className="mb-8">
    {/* Título del Curso o Módulo */}
    <h3 className="text-3xl font-bold text-primary mb-4">
      {title}
    </h3>
    
    {/* Descripción del Curso o Módulo */}
    <p className="text-lg text-secondary-text-color font-poppins">
      {description}
    </p>
  </div>
);

CourseHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default CourseHeader;





