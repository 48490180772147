// EnrollCourseForm.jsx

import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const EnrollCourseForm = ({ courses, handleEnrollStudent, enrolledCourses, loadingCourses, processing }) => {
  console.log('Enrolled courses in EnrollCourseForm:', enrolledCourses); // Para depuración

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
      <h4 className="text-xl font-semibold text-gray-800 mb-4">Inscribir en un Nuevo Curso</h4>
      
      {loadingCourses ? (
        <div className="flex justify-center items-center py-8">
          <FaSpinner className="text-3xl text-primary animate-spin" />
        </div>
      ) : (
        <div>
          {courses.map((course) => {
            const isEnrolled = enrolledCourses.some((enrolled) => enrolled.id === course.id);
            return (
              <div
                key={course.id}
                className="flex justify-between items-center py-4 border-b border-gray-200 hover:bg-gray-50 transition-colors"
              >
                <span className="text-lg font-medium text-gray-700">{course.title}</span>
                <button
                  onClick={() => handleEnrollStudent(course.id)}
                  className={`flex items-center px-4 py-2 rounded-lg text-white transition duration-300 ${
                    isEnrolled || processing
                      ? 'bg-gray-400 cursor-not-allowed'
                      : 'bg-primary hover:bg-primary-hover'
                  }`}
                  disabled={isEnrolled || processing} // Deshabilitar si ya está inscrito o si está procesando
                >
                  {processing ? (
                    <FaSpinner className="animate-spin mr-2" />
                  ) : null}
                  {isEnrolled ? 'Inscrito' : 'Inscribir en Curso'}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EnrollCourseForm;






