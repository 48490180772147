// CreateUserForm.jsx

import React from 'react';
import { FaEnvelope, FaSpinner, FaTimes } from 'react-icons/fa';

const CreateUserForm = ({
  studentEmail,
  setStudentEmail,
  studentFirstName,
  setStudentFirstName,
  studentLastName,
  setStudentLastName,
  handleCreateUser,
  enrolling,
  onClose, // Nueva prop para cerrar el modal
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Crear Usuario y Asignar Código</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>

        {/* Contenedor Flex para Nombres y Apellidos */}
        <div className="flex space-x-4 mb-4">
          <div className="relative w-1/2">
            <input
              type="text"
              placeholder="Nombres del Estudiante"
              value={studentFirstName}
              onChange={(e) => setStudentFirstName(e.target.value)}
              className="w-full pl-4 pr-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200"
            />
          </div>
          <div className="relative w-1/2">
            <input
              type="text"
              placeholder="Apellidos del Estudiante"
              value={studentLastName}
              onChange={(e) => setStudentLastName(e.target.value)}
              className="w-full pl-4 pr-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200"
            />
          </div>
        </div>

        {/* Input para Correo */}
        <div className="relative mb-4">
          <FaEnvelope className="absolute top-1/2 left-4 transform -translate-y-1/2 text-gray-500 text-lg" />
          <input
            type="email"
            placeholder="Correo del Estudiante"
            value={studentEmail}
            onChange={(e) => setStudentEmail(e.target.value)}
            className="w-full pl-12 pr-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition duration-200"
          />
        </div>

        {/* Botón de Crear Usuario */}
        <button
          onClick={handleCreateUser}
          className={`flex items-center justify-center gap-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors duration-200 ${enrolling && 'cursor-not-allowed'}`}
          disabled={enrolling}
        >
          {enrolling ? (
            <FaSpinner className="animate-spin" />
          ) : (
            'Crear Usuario'
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateUserForm;
