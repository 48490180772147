import React from 'react';

const ContactForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevenir el envío por defecto

    const form = event.target;
    const formData = new FormData(form);

    // Convertir FormData a URL-encoded
    const data = new URLSearchParams();
    for (const pair of formData) {
      data.append(pair[0], pair[1]);
    }

    fetch(form.action, {
      method: 'POST',
      body: data,
      mode: 'no-cors' // Evitar problemas CORS
    })
      .then(() => {
        // Redirigir a la página de éxito
        window.location.href = '/success'; 
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('Hubo un error al enviar el formulario. Por favor, intenta nuevamente.');
      });
  };

  return (
    <div id="page-top">
      {/* Contact Section */}
      <section className="page-section pt-10" id="contact">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <h2 className="mt-0">Pre-Inscríbete ahora!</h2>
              <hr className="divider" />
              <label>Déjanos tu mensaje, pronto nos estaremos contactando contigo!</label>
            </div>
          </div>
          <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
            <div className="col-lg-6">
              <form
                id="contactForm"
                action="https://script.google.com/macros/s/AKfycbzmLjjR-U3BXvhIZ-X0AIjIy4cWFDzod--SWumjI2BP-ZlcFkXzYyKk60lL7E7ueKLibA/exec"
                method="post"
                onSubmit={handleSubmit}
              >
                {/* Program selection */}
                <div className="form-floating mb-3">
                  <select className="form-select" id="program" name="program" aria-label="Selecciona un programa" required>
                    <option value="" disabled selected>
                      Selecciona un programa
                    </option>
                    <option value="Bachillerato para Adultos">Bachillerato para Adultos</option>
                    <option value="Técnico Laboral en Atención a la Primera Infancia">
                      Técnico Laboral en Atención a la Primera Infancia
                    </option>
                    <option value="Técnico Laboral en Auxiliar Administrativo">Técnico Laboral en Auxiliar Administrativo</option>
                    <option value="Técnico Laboral en Auxiliar Contable">Técnico Laboral en Auxiliar Contable</option>
                    <option value="Conocimientos Académicos en Teología">Conocimientos Académicos en Teología</option>
                    <option value="Conocimientos Académicos en Música">Conocimientos Académicos en Música</option>
                    <option value="Conocimientos Académicos en Inglés">Conocimientos Académicos en Inglés</option>
                  </select>
                  <label htmlFor="program">Programa de Interés</label>
                </div>

                {/* Name input */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Introduce tu nombre..."
                    required
                  />
                  <label htmlFor="name">Tu Nombre</label>
                  <div className="invalid-feedback" data-sb-feedback="name:required">
                    Se requiere un Nombre.
                  </div>
                </div>

                {/* Phone number input */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="phone"
                    name="phone"
                    type="tel"
                    placeholder="(123) 456-7890"
                    required
                  />
                  <label htmlFor="phone">Número de WhatsApp</label>
                  <div className="invalid-feedback" data-sb-feedback="phone:required">
                    Se requiere un número.
                  </div>
                </div>

                {/* City selection */}
                <div className="form-floating mb-3">
                  <select className="form-select" id="city" name="city" aria-label="Selecciona una Ciudad" required>
                    <option value="" disabled selected>
                      Selecciona una Ciudad
                    </option>
                    {/* Incluye aquí tus opciones de ciudades */}
                    <option value="Bogotá">Bogotá</option>
                    <option value="Medellín">Medellín</option>
                  </select>
                  <label htmlFor="city">Tu Ciudad o Municipio</label>
                </div>

                {/* Message input */}
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Introduce tu mensaje aquí..."
                    style={{ height: '10rem' }}
                    required
                  ></textarea>
                  <label htmlFor="message">Tu mensaje</label>
                  <div className="invalid-feedback" data-sb-feedback="message:required">
                    Falta tu mensaje.
                  </div>
                </div>

                {/* Submit success message */}
                <div id="submitSuccessMessage" className="d-none text-center mb-3">
                  <div className="fw-bolder">Su Inscripción se realizó correctamente</div>
                  pronto nos comunicaremos contigo!
                  <br />
                </div>

                {/* Submit error message */}
                <div id="submitErrorMessage" className="d-none text-center text-danger mb-3">
                  Error enviando el mensaje!
                </div>

                {/* Submit Button */}
                <div className="d-grid">
                  <button className="btn btn-primary btn-xl" id="submitButton" type="submit">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-4 text-center mb-5 mb-lg-0">
              <i className="bi-phone fs-2 mb-3 text-muted"></i>
              <div>+57 (321) 620-0693</div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-light py-5">
        <div className="container px-4 px-lg-5">
          <small className="text-center text-muted">Copyright &copy; 2024 - Corporación Educativa Pentecostal</small>
        </div>
      </footer>

      {/* Bootstrap core JS */}
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
      {/* SimpleLightbox plugin JS */}
      <script src="https://cdnjs.cloudflare.com/ajax/libs/SimpleLightbox/2.1.0/simpleLightbox.min.js"></script>
      {/* Core theme JS */}
      <script src="js/scripts.js"></script>
    </div>
  );
};

export default ContactForm;
