// src/firebase/firestore/lessons.js
import { db } from '../firebaseConfig';
import { collection, addDoc, doc, getDoc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';

// Agregar una lección a un módulo dentro de un curso
export const addLessonToModule = async (courseId, moduleId, lessonData) => {
  try {
    const lessonsCollectionRef = collection(db, 'courses', courseId, 'modules', moduleId, 'lessons');
    await addDoc(lessonsCollectionRef, lessonData);
  } catch (error) {
    console.error('Error al agregar la lección:', error);
    throw error;
  }
};

// Obtener todas las lecciones de un módulo dentro de un curso
export const getModuleLessons = async (courseId, moduleId) => {
  try {
    const lessonsSnapshot = await getDocs(collection(db, 'courses', courseId, 'modules', moduleId, 'lessons'));
    return lessonsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error al obtener las lecciones del módulo:', error);
    throw error;
  }
};

// Obtener detalles de una lección específica
export const getLessonDetails = async (courseId, moduleId, lessonId) => {
  try {
    const lessonDocRef = doc(db, 'courses', courseId, 'modules', moduleId, 'lessons', lessonId);
    const lessonDoc = await getDoc(lessonDocRef);
    if (lessonDoc.exists()) {
      return { id: lessonDoc.id, ...lessonDoc.data() };
    } else {
      throw new Error('Lección no encontrada');
    }
  } catch (error) {
    console.error('Error al obtener los detalles de la lección:', error);
    throw error;
  }
};

// Actualizar una lección existente
export const updateLesson = async (courseId, moduleId, lessonId, lessonData) => {
  try {
    const lessonDocRef = doc(db, 'courses', courseId, 'modules', moduleId, 'lessons', lessonId);
    await updateDoc(lessonDocRef, lessonData);
  } catch (error) {
    console.error('Error al actualizar la lección:', error);
    throw error;
  }
};

// Eliminar una lección
export const deleteLesson = async (courseId, moduleId, lessonId) => {
  try {
    const lessonDocRef = doc(db, 'courses', courseId, 'modules', moduleId, 'lessons', lessonId);
    await deleteDoc(lessonDocRef);
  } catch (error) {
    console.error('Error al eliminar la lección:', error);
    throw error;
  }
};

// **Nuevas Funciones Añadidas para Resolver los Errores**

/**
 * Obtener todas las lecciones de un curso (independientemente de los módulos)
 * Si necesitas una función que obtenga todas las lecciones de un curso,
 * sin importar el módulo, puedes implementarla así:
 */
export const getCourseLessons = async (courseId) => {
  try {
    const modulesSnapshot = await getDocs(collection(db, 'courses', courseId, 'modules'));
    let allLessons = [];
    for (const moduleDoc of modulesSnapshot.docs) {
      const lessonsSnapshot = await getDocs(collection(moduleDoc.ref, 'lessons'));
      const lessons = lessonsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      allLessons = allLessons.concat(lessons);
    }
    return allLessons;
  } catch (error) {
    console.error('Error al obtener las lecciones del curso:', error);
    throw error;
  }
};


