// src/components/ModuleDetail/ModuleDetail.jsx

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getModuleLessons } from "../../firebase/firestore/lessons";
import { getModuleTitle } from "../../firebase/firestore/courses";
import {
  saveLessonProgress,
  getLessonProgress,
  saveExamProgress,
  getExamProgress,
} from "../../firebase/firestore/students";
import { useStudentAuth } from "../../hooks/useStudentAuth";
import CourseHeader from "./CourseHeader";
import Lesson from "./Lesson";
import Sidebar from "./Sidebar";
import Loader from "../Loader/Loader";
import Message from "./Message";
import Exam from "./Exam";
import Certificate from "./Certificate";
import { FiAlertTriangle } from "react-icons/fi";

const ModuleDetail = () => {
  const { studentUser } = useStudentAuth();
  const { courseId, moduleId } = useParams();
  const [moduleTitle, setModuleTitle] = useState("");
  const [lessons, setLessons] = useState([]);
  const [completedLessons, setCompletedLessons] = useState([]);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isExamReady, setIsExamReady] = useState(false);
  const [examQuestions, setExamQuestions] = useState([]);
  const [examPassed, setExamPassed] = useState(false);
  const [examFailed, setExamFailed] = useState(false); 


  // Función para preparar el examen
  const prepareExam = () => {
    // Extraer todas las preguntas de todas las lecciones completadas
    const allQuestions = lessons.flatMap((lesson) =>
      lesson.questions ? lesson.questions : []
    );

    if (allQuestions.length < 3) {
      setError("No hay suficientes preguntas para el examen.");
    } else {
      // Seleccionar aleatoriamente tres preguntas
      const selectedQuestions = [];
      while (selectedQuestions.length < 3) {
        const randomIndex = Math.floor(Math.random() * allQuestions.length);
        const question = allQuestions[randomIndex];
        if (!selectedQuestions.includes(question)) {
          selectedQuestions.push(question);
        }
      }
      setExamQuestions(selectedQuestions);
      setIsExamReady(true);
      console.log("Examen preparado con preguntas:", selectedQuestions); // Para depuración
    }
  };

  useEffect(() => {
    fetchModuleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, moduleId]);

  useEffect(() => {
    // Si todas las lecciones están completadas, preparar el examen
    if (lessons.length > 0 && completedLessons.length === lessons.length) {
      prepareExam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedLessons, lessons]); // Eliminado examAttempts

  // Función para obtener los detalles del módulo
  const fetchModuleDetails = async () => {
    setLoading(true);
    setError("");
    try {
      const title = await getModuleTitle(courseId, moduleId);
      setModuleTitle(title);
      
      const lessonsData = await getModuleLessons(courseId, moduleId);
    
      // Asegurarse de que lessonsData sea un arreglo
      if (!Array.isArray(lessonsData)) {
        throw new Error("Error al obtener las lecciones. Datos inválidos.");
      }

      // Ordenar las lecciones alfabéticamente por título
      const sortedLessons = lessonsData
        .slice()
        .sort((a, b) => a.title.localeCompare(b.title));

      setLessons(sortedLessons);

      // Obtener el progreso del estudiante usando su correo
      const studentProgress = await getLessonProgress(
        studentUser.email,
        courseId,
        moduleId
      );
      

      // Asegurarse de que studentProgress sea un arreglo
      const validProgress = Array.isArray(studentProgress)
        ? studentProgress
        : [];

      setCompletedLessons(validProgress);

      // Establecer la lección actual como la primera no completada si el examen no está aprobado
      if (!examPassed) {
        const firstIncompleteLessonIndex = sortedLessons.findIndex(
          (lesson) => !validProgress.includes(lesson.id)
        );
        setCurrentLessonIndex(
          firstIncompleteLessonIndex !== -1 ? firstIncompleteLessonIndex : 0
        );
        console.log(
          "Índice de la primera lección incompleta:",
          firstIncompleteLessonIndex
        ); // Para depuración
      }

      // Verificar si el examen ya está aprobado
      const examProgress = await getExamProgress(
        studentUser.email,
        courseId,
        moduleId
      );
      setExamPassed(examProgress);
      console.log("Examen aprobado:", examProgress); // Para depuración

      // Si el examen está aprobado, no preparar el examen y pasar directamente al certificado
      if (examProgress) {
        setIsExamReady(false); // Evitar que el examen sea visible si ya está aprobado
      }
    } catch (error) {
      setError("Error al obtener los detalles del módulo.");
      console.error("Error al obtener los detalles del módulo:", error);
    } finally {
      setLoading(false);
    }
  };

  // Función para manejar el envío de respuestas de lección
  const handleAnswerSubmit = async (selectedOptions, errorMessage) => {
    if (errorMessage) {
      setMessage(errorMessage);
      return;
    }

    const currentLesson = lessons[currentLessonIndex];
    console.log(`Lección actual: ${currentLesson.title}`); // Para depuración

    try {
      // Guardar el progreso del estudiante usando su correo
      await saveLessonProgress(
        studentUser.email,
        courseId,
        moduleId,
        currentLesson.id
      );
      console.log(`Progreso guardado para la lección: ${currentLesson.id}`); // Para depuración

      // Actualizar `completedLessons` sin duplicados
      setCompletedLessons((prev) => {
        const updatedCompletedLessons = Array.from(
          new Set([...prev, currentLesson.id])
        );
        setMessage("¡Lección completada correctamente!");
        console.log(
          "Lecciones completadas actualizadas:",
          updatedCompletedLessons
        ); // Para depuración

        // Dirigir automáticamente a la siguiente lección incompleta
        const nextLessonIndex = lessons.findIndex(
          (lesson) => !updatedCompletedLessons.includes(lesson.id)
        );

        if (nextLessonIndex !== -1) {
          // Si hay una lección pendiente, actualizar el índice
          setCurrentLessonIndex(nextLessonIndex);
        } else if (updatedCompletedLessons.length === lessons.length) {
          // Si todas las lecciones están completadas, preparar el examen
          setIsExamReady(true);
        }

        return updatedCompletedLessons;
      });

      window.scrollTo(0, 0); // Mover al inicio de la página
    } catch (error) {
      setError("Error al guardar el progreso.");
      console.error("Error al guardar el progreso:", error);
    }
  };

// Función para manejar el envío del examen
// Función para manejar el envío del examen
const handleExamSubmit = async (score) => {
  console.log(`handleExamSubmit called with score: ${score}`); // Para depuración
  if (score >= 90) {
    try {
      const completionDate = new Date().toISOString(); // Cambiar a formato ISO para mejor manejo de la fecha
      await saveExamProgress(studentUser.email, courseId, moduleId, {
        completionDate: completionDate,
      });

      setExamPassed(true);
      setMessage(
        "¡Felicidades! Has aprobado el examen y recibido tu certificado."
      );
      console.log("Examen aprobado"); // Para depuración
    } catch (error) {
      setError("Error al guardar el progreso del examen.");
      console.error("Error al guardar el progreso del examen:", error);
    }
  } else {
    // Mostrar mensaje de no aprobación
    setMessage(
      "No has aprobado el examen. Por favor, revisa las lecciones y vuelve a intentarlo."
    );
    setIsExamReady(false); // Ocultar el examen

    // Mostrar la pantalla de examen no aprobado
    setExamFailed(true); // Variable para mostrar pantalla de examen fallido
    console.log("Examen no aprobado"); // Para depuración
  }
};


  // Pantalla de examen fallido con redirección
  const renderExamFailedScreen = () => {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg flex flex-col items-center mt-6">
        <div className="flex items-center mb-4">
          <FiAlertTriangle className="w-6 h-6 text-red-500 mr-2" />
          <span className="font-bold text-lg">Examen No Aprobado</span>
        </div>
        <p className="text-center mb-4">{message}</p>
        <button
          onClick={() => {
            setCurrentLessonIndex(lessons.length - 1);
            setExamFailed(false); 
            setMessage(""); 
            setError("");
          }}
          className="bg-primary hover:bg-primary-hover text-white py-2 px-4 rounded-lg transition-colors duration-300 font-semibold"
        >
          Volver a la Última Lección
        </button>
      </div>
    );
  };
  

  // Calcular el progreso
  const progress =
    lessons.length > 0 ? (completedLessons.length / lessons.length) * 100 : 0;

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message type="error">{error}</Message>;
  }

  return (
    <div className="flex flex-col lg:flex-row bg-background min-h-screen p-5 gap-6">
      {/* Área de Contenido Principal */}
      <div className="flex-1 bg-card-background p-6 rounded-lg shadow-md max-w-4xl w-full mx-auto">
        {/* Header del curso o módulo */}
        <CourseHeader title={moduleTitle} />
        {/* Barra de Progreso */}
        <div className="mb-6">
          <div className="flex items-center justify-between mb-2">
            <span className="text-sm text-secondary-text-color font-semibold">
              Progreso: {Math.round(progress)}%
            </span>
            <span className="text-sm text-secondary-text-color font-semibold">
              {Math.round(progress)}%
            </span>
          </div>
          <div className="w-full bg-gray-300 rounded-full h-3">
            <div
              className="bg-primary h-3 rounded-full transition-all duration-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
  
        {/* Contenido Principal */}
        {examPassed ? (
          <Certificate
            courseTitle={moduleTitle}
            studentUser={studentUser}
            completionDate={new Date().toLocaleDateString()}
          />
        ) : examFailed ? ( // Condición para mostrar la pantalla de examen fallido
          renderExamFailedScreen() 
        ) : isExamReady ? (
          <Exam questions={examQuestions} onSubmit={handleExamSubmit} />
        ) : (
          <div>
            {/* Mostrar lección actual */}
            {lessons.length > 0 ? (
              <Lesson
                lesson={lessons[currentLessonIndex]}
                handleAnswerSubmit={handleAnswerSubmit}
              />
            ) : (
              <Message type="warning">No hay lecciones disponibles.</Message>
            )}
          </div>
        )}
      </div>
  
      {/* Sidebar con las lecciones del módulo */}
      <div className="w-full lg:w-1/4 bg-card-background p-6 rounded-lg shadow-md max-w-sm">
        <Sidebar
          lessons={lessons}
          currentLessonIndex={currentLessonIndex}
          completedLessons={completedLessons}
          setCurrentLessonIndex={setCurrentLessonIndex}
          isFinalExamReady={isExamReady}
          isFinalExamPassed={examPassed}
        />
      </div>
    </div>
  );
  
};

export default ModuleDetail;
