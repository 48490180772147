// src/firebase/firestore/modules.js
import { db } from '../firebaseConfig';
import { collection, addDoc, doc, getDoc, getDocs, updateDoc, deleteDoc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';

// Agregar un módulo a un curso existente
export const addModuleToCourse = async (courseId, moduleData) => {
  try {
    const modulesCollectionRef = collection(db, 'courses', courseId, 'modules');
    await addDoc(modulesCollectionRef, moduleData);
  } catch (error) {
    console.error('Error al agregar el módulo:', error);
    throw error;
  }
};

// Obtener todos los módulos de un curso
export const getCourseModules = async (courseId) => {
  try {
    const modulesSnapshot = await getDocs(collection(db, 'courses', courseId, 'modules'));
    return modulesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error al obtener los módulos del curso:', error);
    throw error;
  }
};

// Obtener detalles de un módulo específico, incluyendo lecciones
export const getModuleDetails = async (courseId, moduleId) => {
  try {
    const moduleDocRef = doc(db, 'courses', courseId, 'modules', moduleId);
    const moduleDoc = await getDoc(moduleDocRef);

    if (moduleDoc.exists()) {
      const moduleData = { id: moduleDoc.id, ...moduleDoc.data() };
      // Obtener lecciones del módulo
      const lessonsSnapshot = await getDocs(collection(moduleDocRef, 'lessons'));
      moduleData.lessons = lessonsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return moduleData;
    } else {
      throw new Error('Módulo no encontrado');
    }
  } catch (error) {
    console.error('Error al obtener los detalles del módulo:', error);
    throw error;
  }
};

// Actualizar un módulo existente
export const updateModule = async (courseId, moduleId, moduleData) => {
  try {
    const moduleDocRef = doc(db, 'courses', courseId, 'modules', moduleId);
    await updateDoc(moduleDocRef, moduleData);
  } catch (error) {
    console.error('Error al actualizar el módulo:', error);
    throw error;
  }
};

// Eliminar un módulo
export const deleteModule = async (courseId, moduleId) => {
  try {
    const moduleDocRef = doc(db, 'courses', courseId, 'modules', moduleId);
    await deleteDoc(moduleDocRef);
  } catch (error) {
    console.error('Error al eliminar el módulo:', error);
    throw error;
  }
};

// **Nuevas Funciones Añadidas para Resolver los Errores**

/**
 * Activar un módulo para un estudiante
 */
export const activateStudentModule = async (courseId, moduleId, studentId) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    await updateDoc(studentDocRef, {
      [`enrolledCourses.${courseId}.modules.${moduleId}.isActive`]: true,
    });
  } catch (error) {
    console.error('Error al activar el módulo para el estudiante:', error);
    throw error;
  }
};

/**
 * Desactivar un módulo para un estudiante
 */
export const deactivateStudentModule = async (courseId, moduleId, studentId) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    await updateDoc(studentDocRef, {
      [`enrolledCourses.${courseId}.modules.${moduleId}.isActive`]: false,
    });
  } catch (error) {
    console.error('Error al desactivar el módulo para el estudiante:', error);
    throw error;
  }
};

/**
 * Escucha en tiempo real los módulos de un curso
 * @param {string} courseId - ID del curso
 * @param {function} callback - Función para manejar la actualización de módulos en tiempo real
 */
export const listenToModules = (courseId, callback) => {
  const modulesCollectionRef = collection(db, 'courses', courseId, 'modules');
  return onSnapshot(modulesCollectionRef, (snapshot) => {
    const modules = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    callback(modules);
  });
};