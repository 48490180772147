// src/components/Success.jsx
import React from 'react';

const Success = () => {
  return (
    <div id="page-top">
     
      {/* Sección de Mensaje de Éxito */}
      <section className="page-section" id="success">
        <div className="container px-4 px-lg-5">
          <div className="row gx-4 gx-lg-5 justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <h2 className="mt-0">¡Inscripción Exitosa!</h2>
              <hr className="divider" />
              <p className="text-muted mb-5">
                Tu mensaje ha sido enviado correctamente. Nos pondremos en contacto contigo pronto.
              </p>
              <a className="btn btn-primary btn-xl" href="/">
                Ir al Inicio
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Success;
