import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Bachillerato from "./pages/bachillerato";
import Administrativo from "./pages/administrativo";
import Infancia from "./pages/infancia";
import Contable from "./pages/contable";
import ContactForm from "./pages/preinscripcion";
import Header from "./components/Header";
import "./scss/styles.scss";
import Footer from "./components/Footer";
import ScrollToTop from './components/ScrollToTop';
import Ingles from "./pages/ingles";
import Musica from "./pages/musica";
import Teologia from "./pages/teologia";
import Success from "./pages/success";



import LmsApp from './lms/App';

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/campus')) {
      // Cargar los estilos del LMS solo cuando se esté en la ruta de campus
      import('./lms/index.css');
    }
  }, [location.pathname]);

  const isCampusRoute = location.pathname.startsWith('/campus');

  return (
    <>
      {/* Mostrar Header y Footer solo si no estamos en la ruta /campus */}
      {!isCampusRoute && <Header />}
      <div className="content-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bachillerato" element={<Bachillerato />} />
          <Route path="/preinscripcion" element={<ContactForm />} />
          <Route path="/administrativo" element={<Administrativo />} />
          <Route path="/musica" element={<Musica />} />
          <Route path="/ingles" element={<Ingles />} />
          <Route path="/infancia" element={<Infancia />} />
          <Route path="/contable" element={<Contable />} />
          <Route path="/teologia" element={<Teologia />} />
          <Route path="/success" element={<Success />} />

          {/* Add a route for the LMS */}
          <Route path="/campus/*" element={<LmsApp />} />
        </Routes>
      </div>
      {!isCampusRoute && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;

