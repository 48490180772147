import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useStudentAuth } from '../hooks/useStudentAuth';
import { db } from '../firebase/firebaseConfig';
import { getStudentCourses } from '../firebase/firestore/students';
import { getCourseModules } from '../firebase/firestore/modules';

const StudentDashboard = () => {
  const { studentUser } = useStudentAuth();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const navigate = useNavigate(); // Para manejar la navegación

  useEffect(() => {
    if (studentUser) {
      fetchStudentCourses(studentUser.email);
    } else {
      setShowLoginModal(true);
    }
  }, [studentUser]);

  const fetchStudentCourses = async (studentEmail) => {
    setLoading(true);
    setError('');
    try {
      const coursesData = await getStudentCourses(studentEmail);
      setCourses(coursesData);
    } catch (error) {
      setError('Error al obtener los cursos.');
      console.error('Error al obtener los cursos:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchModules = async (course) => {
    setLoading(true);
    setError('');
    setSelectedCourse(course);
    try {
      const modulesData = await getCourseModules(course.id);
      const studentDocRef = doc(db, 'students', studentUser.email);
      const studentDoc = await getDoc(studentDocRef);

      if (studentDoc.exists()) {
        const studentData = studentDoc.data();
        const enrolledCourses = studentData.enrolledCourses;

        const activeModules = enrolledCourses?.[course.id]?.modules
          ? modulesData.filter(module => enrolledCourses[course.id].modules[module.id]?.isActive)
          : [];

        setModules(activeModules);
      } else {
        setModules([]);
      }
    } catch (error) {
      setError('Error al obtener los módulos del curso.');
      console.error('Error al obtener los módulos del curso:', error);
      setModules([]);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowLoginModal(false);
  };

  return (
    <div className="min-h-screen bg-background p-5">
      {showLoginModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 max-w-md shadow-lg">
            <h2 className="text-2xl font-bold text-primary mb-4">¡No has iniciado sesión!</h2>
            <p className="text-gray-700 mb-6">Por favor, inicia sesión para acceder a tus cursos y progresar.</p>
            <div className="flex justify-end space-x-4">
              <Link to="/campus/login" className="bg-primary hover:bg-primary-hover text-white py-2 px-4 rounded-lg font-semibold">
                Iniciar Sesión
              </Link>
              <button onClick={closeModal} className="bg-secondary hover:bg-secondary-hover text-white py-2 px-4 rounded-lg font-semibold">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}

      <header className="mb-8 text-center">
        <h1 className="text-4xl font-bold text-primary mb-2">Mis Cursos</h1>
        <p className="text-lg text-secondary-text-color">Selecciona un curso para ver sus módulos activos y seguir tu progreso.</p>
      </header>

      {loading ? (
        <div className="flex justify-center items-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          <p className="ml-4 text-gray-700">Cargando...</p>
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row gap-6 justify-center items-start">
          {/* Lista de Cursos */}
          <div className="w-full lg:w-3/4">
            {courses.length === 0 ? (
              <p className="text-center text-gray-700">No tienes cursos disponibles.</p>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {courses.map(course => (
                  <div key={course.id} className="bg-card-background rounded-lg shadow-md p-4 flex flex-col items-center">
                    <img src={course.imageUrl} alt={course.title} className="h-40 w-80 object-cover rounded-lg mb-4" />
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">{course.title}</h3>
                    <button
                      onClick={() => fetchModules(course)}
                      className="bg-primary hover:bg-primary-hover text-white py-2 px-4 rounded-lg font-semibold"
                    >
                      Ver Módulos
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Lista de Módulos */}
          <div className="w-full lg:w-2/5 flex flex-col items-center justify-center">
            {selectedCourse && (
              <div className="mb-6 w-full">
                <h2 className="text-2xl font-bold text-primary mb-4 text-center">Módulos en {selectedCourse.title}</h2>
                {modules.length === 0 ? (
                  <p className="text-gray-700 text-center">No tienes módulos activos en este curso.</p>
                ) : (
                  <div className="">
                    {modules.map(module => (
                      <div
                        key={module.id}
                        className="bg-card-background rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300 w-full"
                      >
                        <div className="flex flex-col items-center w-full">
                          <h3 className="text-xl font-semibold text-gray-800 mb-2">{module.title}</h3>
                          <button
                            onClick={() => navigate(`/campus/course/${selectedCourse.id}/module/${module.id}`)} // Redirigir al detalle del módulo
                            className="bg-secondary hover:bg-secondary-hover text-white py-2 px-4 rounded-lg font-medium mt-4"
                          >
                            Ver Lecciones
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {error && (
        <p className="text-red-600 text-center mt-6">{error}</p>
      )}
    </div>
  );
};

export default StudentDashboard;









