// src/components/Footer/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p>&copy; 2024 Plataforma LMS. Todos los derechos reservados.</p>
        <ul className={styles.footerLinks}>
          <li>
            <Link to="/privacy" className={styles.footerLink}>
              Privacidad
            </Link>
          </li>
          <li>
            <Link to="/terms" className={styles.footerLink}>
              Términos y Condiciones
            </Link>
          </li>
          <li>
            <Link to="/contact" className={styles.footerLink}>
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
