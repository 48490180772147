// src/firebase/firestore/students.js
import { db } from '../firebaseConfig';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  getDocs, 
  updateDoc, 
  deleteDoc, 
  arrayUnion,  
  deleteField,
  query,
  where
} from 'firebase/firestore'; 
import { generateCode } from '../../utils/generateCode';

//-----------------------------------------------------------------------
// Nueva función: Actualizar el índice de la lección actual del estudiante
//-----------------------------------------------------------------------

export const updateCurrentLessonIndex = async (studentEmail, courseId, moduleId, currentIndex) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      await updateDoc(studentDocRef, {
        [`progress.${courseId}.${moduleId}.currentLessonIndex`]: currentIndex,
      });
      console.log(`Índice de lección actualizado para el estudiante ${studentEmail}`);
    } else {
      throw new Error('Estudiante no encontrado');
    }
  } catch (error) {
    console.error('Error al actualizar el índice de la lección:', error);
    throw error;
  }
};


// Obtener cursos inscritos por un estudiante
export const getStudentCourses = async (studentId) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    const studentDoc = await getDoc(studentDocRef);
    
    if (studentDoc.exists()) {
      const studentData = studentDoc.data();
      const enrolledCourses = studentData.enrolledCourses || {};
      
      const courseIds = Object.keys(enrolledCourses);
      const courses = [];

      for (const courseId of courseIds) {
        const courseDocRef = doc(db, 'courses', courseId);
        const courseDoc = await getDoc(courseDocRef);
        if (courseDoc.exists()) {
          courses.push({ id: courseDoc.id, ...courseDoc.data() });
        }
      }

      return courses;
    } else {
      throw new Error('Estudiante no encontrado.');
    }
  } catch (error) {
    console.error('Error al obtener los cursos del estudiante:', error);
    throw error;
  }
};


// Guardar el progreso del estudiante
export const saveLessonProgress = async (studentEmail, courseId, moduleId, lessonId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail); // Usar el correo electrónico como ID del documento
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const progress = studentDoc.data().progress || {};
      const courseProgress = progress[courseId] || {};
      const moduleProgress = courseProgress[moduleId] || [];

      // Añadir la lección completada si aún no está en el progreso
      if (!moduleProgress.includes(lessonId)) {
        moduleProgress.push(lessonId);
        await updateDoc(studentDocRef, {
          [`progress.${courseId}.${moduleId}`]: moduleProgress,
        });
      }
    } else {
      throw new Error('Estudiante no encontrado');
    }
  } catch (error) {
    console.error('Error al guardar el progreso:', error);
    throw error;
  }
};


//---------------------------------------------------------------------------------------------------------------------------------------


// Agregar un nuevo estudiante a la colección 'students'
export const addStudent = async (studentData) => {
  try {
    const email = studentData.email.trim().toLowerCase();
    const firstName = studentData.firstName.trim();
    const lastName = studentData.lastName.trim();

    // Verificar si el estudiante ya existe
    const studentsCollectionRef = collection(db, 'students');
    const q = query(studentsCollectionRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      throw new Error('El estudiante con este correo ya existe.');
    }

    // Crear el documento del estudiante
    const studentRef = doc(db, 'students', email);
    const code = generateCode();
    await setDoc(studentRef, {
      email,
      firstName,
      lastName,
      accessCode: code,
      enrolledCourses: {},
      createdAt: new Date(),
    });
    return code;
  } catch (error) {
    console.error('Error al agregar el estudiante:', error);
    throw error;
  }
};

// Obtener todos los estudiantes
export const getStudents = async () => {
  try {
    const studentsSnapshot = await getDocs(collection(db, 'students'));
    return studentsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      firstName: doc.data().firstName || '',
      lastName: doc.data().lastName || ''
    }));
  } catch (error) {
    console.error('Error al obtener los estudiantes:', error);
    throw error;
  }
};


// Eliminar completamente un estudiante
export const removeStudent = async (studentEmail) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    await deleteDoc(studentDocRef); // Eliminar completamente el documento del estudiante
    console.log(`Estudiante ${studentEmail} eliminado correctamente.`);
  } catch (error) {
    console.error('Error al eliminar al estudiante:', error);
    throw error;
  }
};



// Eliminar un estudiante de la colección 'students' o de un curso específico
export const removeStudentFromCourse = async (studentId, courseId = null) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    
    if (courseId) {
      // Si se proporciona un courseId, solo eliminarlo de los cursos inscritos
      await updateDoc(studentDocRef, {
        [`enrolledCourses.${courseId}`]: deleteField()
      });
    } else {
      // Si no se proporciona un courseId, eliminar el documento del estudiante por completo
      await deleteDoc(studentDocRef);
    }
  } catch (error) {
    console.error('Error al eliminar al estudiante o del curso:', error);
    throw error;
  }
};


// Actualizar el progreso del estudiante en un módulo y lección
export const updateStudentProgress = async (studentId, courseId, moduleId, lessonId) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    const enrolledCourseRef = `enrolledCourses.${courseId}.modules.${moduleId}.lessonsCompleted`;

    await updateDoc(studentDocRef, {
      [enrolledCourseRef]: arrayUnion(lessonId)
    });

    // Opcional: Actualizar el progreso total del curso
  } catch (error) {
    console.error('Error al actualizar el progreso del estudiante:', error);
    throw error;
  }
};

// Obtener el progreso del estudiante en un curso
export const getStudentProgress = async (studentId, courseId) => {
  try {
    const studentDocRef = doc(db, 'students', studentId);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const studentData = studentDoc.data();
      const enrolledCourses = studentData.enrolledCourses || {};
      const courseProgress = enrolledCourses[courseId] || {};
      return courseProgress;
    } else {
      throw new Error('Estudiante no encontrado.');
    }
  } catch (error) {
    console.error('Error al obtener el progreso del estudiante:', error);
    throw error;
  }
};

// Obtener estudiantes de un curso
export const getCourseStudents = async (courseId) => {
  try {
    const studentsSnapshot = await getDocs(collection(db, 'students'));
    const enrolledStudents = [];

    studentsSnapshot.forEach(doc => {
      const studentData = doc.data();
      if (studentData.enrolledCourses && studentData.enrolledCourses[courseId]) {
        enrolledStudents.push({ id: doc.id, ...studentData });
      }
    });

    return enrolledStudents;
  } catch (error) {
    console.error('Error al obtener los estudiantes del curso:', error);
    throw error;
  }
};



export const enrollStudentInCourse = async (studentEmail, courseId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    
    // Verificar si el estudiante ya existe
    const studentDoc = await getDoc(studentDocRef);
    if (!studentDoc.exists()) {
      throw new Error('Estudiante no encontrado.');
    }

    // Verificar si el curso ya está inscrito
    const studentData = studentDoc.data();
    if (studentData.enrolledCourses && studentData.enrolledCourses[courseId]) {
      throw new Error('El estudiante ya está inscrito en este curso.');
    }

    // Inscribir al estudiante en el curso
    await updateDoc(studentDocRef, {
      [`enrolledCourses.${courseId}`]: {
        modules: {},
        progress: 0
      }
    });

    console.log(`Estudiante ${studentEmail} inscrito en el curso ${courseId}`);
    return true;
  } catch (error) {
    console.error('Error al inscribir al estudiante en el curso:', error);
    throw error;
  }
};

// Obtener el progreso del estudiante
export const getLessonProgress = async (studentEmail, courseId, moduleId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const progress = studentDoc.data().progress || {};
      return progress[courseId]?.[moduleId] || [];
    }
    return [];
  } catch (error) {
    console.error('Error al obtener el progreso:', error);
    throw error;
  }
};

// Marcar que el estudiante está listo para el examen final
export const markFinalExamReady = async (studentEmail, courseId, moduleId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);

    // Actualizar el estado del examen final como listo
    await updateDoc(studentDocRef, {
      [`progress.${courseId}.${moduleId}.finalExamReady`]: true,
    });
    console.log(`Examen final listo para el estudiante ${studentEmail} en módulo ${moduleId}`);
  } catch (error) {
    console.error('Error al marcar el examen final como listo:', error);
    throw error;
  }
};

// Obtener el estado del examen final para el estudiante
export const getFinalExamStatus = async (studentEmail, courseId, moduleId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const progress = studentDoc.data().progress || {};
      return progress[courseId]?.[moduleId]?.finalExamReady || false;
    }
    return false;
  } catch (error) {
    console.error('Error al obtener el estado del examen final:', error);
    throw error;
  }
};

// Guardar el progreso del examen final del estudiante
export const saveExamProgress = async (studentEmail, courseId, moduleId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const completionDate = new Date().toISOString(); 

      await updateDoc(studentDocRef, {
        [`progress.${courseId}.${moduleId}.examPassed`]: true,
        [`progress.${courseId}.${moduleId}.completionDate`]: completionDate, // Guardar la fecha de aprobación
      });

      console.log(`Examen final aprobado y fecha guardada para ${studentEmail}`);
    } else {
      throw new Error('Estudiante no encontrado');
    }
  } catch (error) {
    console.error('Error al guardar el progreso del examen final:', error);
    throw error;
  }
};


// Obtener el progreso del examen final del estudiante
export const getExamProgress = async (studentEmail, courseId, moduleId) => {
  try {
    const studentDocRef = doc(db, 'students', studentEmail);
    const studentDoc = await getDoc(studentDocRef);

    if (studentDoc.exists()) {
      const studentData = studentDoc.data();
      const examPassed =
        studentData.progress?.[courseId]?.[moduleId]?.examPassed || false;
      return examPassed;
    } else {
      throw new Error('Estudiante no encontrado.');
    }
  } catch (error) {
    console.error('Error al obtener el progreso del examen final:', error);
    throw error;
  }
};


// Actualizar los nombres del estudiante
export const updateStudentName = async (email, firstName, lastName) => {
  try {
    // Obtener referencia del documento del estudiante
    const studentRef = doc(db, 'students', email);
    const studentSnap = await getDoc(studentRef);

    // Verificar si el estudiante existe
    if (!studentSnap.exists()) {
      throw new Error('El estudiante no existe en la base de datos.');
    }

    // Actualizar los datos del estudiante
    await updateDoc(studentRef, {
      firstName,
      lastName,
      updatedAt: new Date(),
    });

    console.log('Actualización exitosa para:', email);

  } catch (error) {
    console.error('Error al actualizar el nombre del estudiante:', error);
    throw error; // Lanzar el error para manejarlo en el frontend
  }
};

export const getStudentByEmail = async (email) => {
  try {
    const studentDocRef = doc(db, 'students', email);
    const studentDoc = await getDoc(studentDocRef);
    if (studentDoc.exists()) {
      return { email, ...studentDoc.data() };
    } else {
      throw new Error('Estudiante no encontrado');
    }
  } catch (error) {
    console.error('Error al obtener el estudiante:', error);
    throw error;
  }
};

