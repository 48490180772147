import React from 'react';

const PlanDeEstudio = ({ title, subtitle, items }) => {
  return (
    <section>
      <h2 className="text-center">{title}</h2>
      {subtitle && <h3 className="text-center">{subtitle}</h3>}
      <hr className="divider" />
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item.title && <strong>{item.title}:</strong>} {item.description}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PlanDeEstudio;
