import React from 'react';

const CourseItem = ({ course, onEdit, onDelete, onSelect }) => {
  return (
    <li className="flex justify-between items-center p-4 border border-gray-200 rounded-lg shadow-sm bg-white hover:shadow-md transition-shadow duration-200">
      <div onClick={onSelect} className="flex items-center space-x-4 cursor-pointer">
        <img src={course.imageUrl} alt={course.title} className="w-20 h-20 object-cover rounded-lg" />
        <div>
          <h4 className="text-lg font-semibold text-primary">{course.title}</h4>
          <p className="text-sm text-gray-600">{course.description}</p>
        </div>
      </div>
      <div className="flex space-x-2">
        <button onClick={onEdit} className="bg-secondaryblue text-white py-1 px-3 rounded-lg hover:bg-secondary-hover transition-colors">
          Editar
        </button>
        <button onClick={onDelete} className="bg-danger text-white py-1 px-3 rounded-lg hover:bg-danger-hover transition-transform transform hover:scale-105">
          Eliminar
        </button>
      </div>
    </li>
  );
};

export default CourseItem;
