// src/components/AdminUsers/AdminProgress.jsx
import React, { useState, useEffect } from 'react';
import { getCourseStudents, getStudentProgress } from '../../firebase/firestore/students';
import CourseSelector from '../AdminModules/CourseSelector';
import { getCourses } from "../../firebase/firestore/courses";
import { FaSpinner } from 'react-icons/fa';

const AdminProgress = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [students, setStudents] = useState([]);
  const [progress, setProgress] = useState({});
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  // Función para obtener los cursos desde Firestore
  const fetchCourses = async () => {
    setLoadingCourses(true);
    setError('');
    try {
      const courseData = await getCourses();
      setCourses(courseData);
    } catch (error) {
      setError('Error al cargar los cursos.');
      console.error('Error al cargar los cursos:', error);
    } finally {
      setLoadingCourses(false);
    }
  };

  // Función para obtener los estudiantes de un curso
  const fetchStudents = async (courseId) => {
    setSelectedCourse(courseId);
    setLoadingStudents(true);
    setError('');
    try {
      const studentsData = await getCourseStudents(courseId);
      setStudents(studentsData);
      await fetchStudentProgress(courseId, studentsData);
    } catch (error) {
      setError('Error al cargar los estudiantes del curso.');
      console.error('Error al cargar los estudiantes:', error);
    } finally {
      setLoadingStudents(false);
    }
  };

  // Función para obtener el progreso de los estudiantes
  const fetchStudentProgress = async (courseId, students) => {
    try {
      const progressData = await Promise.all(
        students.map(async (student) => {
          const prog = await getStudentProgress(student.email, courseId);
          return { email: student.email, progress: prog };
        })
      );
      const progressMap = progressData.reduce((acc, curr) => {
        acc[curr.email] = curr.progress;
        return acc;
      }, {});
      setProgress(progressMap);
    } catch (error) {
      setError('Error al cargar el progreso de los estudiantes.');
      console.error('Error al cargar el progreso de los estudiantes:', error);
    }
  };

  // Función para calcular el porcentaje de progreso
  const calculateProgressPercentage = (progress) => {
    const completedModules = Object.values(progress).filter((module) => module).length;
    const totalModules = Object.keys(progress).length;
    return totalModules === 0 ? 0 : (completedModules / totalModules) * 100;
  };

  return (
    <div className="max-w-7xl mx-auto p-8 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">Progreso de Estudiantes</h2>

      {/* Componente de Selección de Curso */}
      <div className="bg-gray-50 p-6 mb-6 rounded-lg shadow-md">
        <CourseSelector
          courses={courses}
          onSelect={fetchStudents}
          loading={loadingCourses}
        />
      </div>

      {/* Mostrar progreso de los estudiantes cuando se selecciona un curso */}
      {selectedCourse && (
        <div className="bg-gray-50 p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold text-secondary mb-4">
            Progreso de los Estudiantes en {courses.find(course => course.id === selectedCourse)?.title}
          </h3>

          {/* Indicador de Carga de Estudiantes */}
          {loadingStudents ? (
            <div className="flex justify-center items-center py-8">
              <FaSpinner className="text-4xl text-blue-500 animate-spin" />
            </div>
          ) : (
            <ul className="space-y-4">
              {students.map((student) => (
                <li key={student.email} className="p-4 bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow">
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
                    <span className="text-lg font-medium text-gray-800">{student.email}</span>
                    {progress[student.email] ? (
                      <div className="mt-2 sm:mt-0 sm:w-1/2">
                        <div className="flex items-center gap-2">
                          <div className="w-full bg-gray-200 rounded-full h-4">
                            <div
                              className="bg-primary h-4 rounded-full"
                              style={{ width: `${calculateProgressPercentage(progress[student.email])}%` }}
                            ></div>
                          </div>
                          <span className="text-sm text-gray-700">
                            {Math.round(calculateProgressPercentage(progress[student.email]))}% Completado
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span className="text-sm text-gray-500">Sin progreso</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {/* Mostrar error si existe */}
      {error && (
        <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}
    </div>
  );
};

export default AdminProgress;

