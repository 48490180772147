// src/components/AdminLessons/LessonForm.jsx

import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes, FaPlus, FaTrash, FaSpinner } from 'react-icons/fa';

const LessonForm = ({
  courses,
  selectedCourse,
  onCourseChange,
  onAddLesson,
  onUpdateLesson,
  editingLesson,
  setMessage,
  setEditingLesson,
  processing,
}) => {
  const [lessonIndex, setLessonIndex] = useState(""); // Estado para el índice de la lección
  const [lessonTitle, setLessonTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [description, setDescription] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (editingLesson) {
      setLessonIndex(editingLesson.index); // Cargar el índice de la lección para editar
      setLessonTitle(editingLesson.title);
      setVideoUrl(editingLesson.videoUrl);
      setDescription(editingLesson.description);
      setQuestions(editingLesson.questions || []);
    } else {
      setLessonIndex("");
      setLessonTitle("");
      setVideoUrl("");
      setDescription("");
      setQuestions([]);
    }
  }, [editingLesson]);

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      { question: "", options: ["", "", "", ""], answer: "" },
    ]);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[oIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (qIndex) => {
    const updatedQuestions = questions.filter((_, index) => index !== qIndex);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isLessonIndexValid = lessonIndex.trim() !== "";
    const isLessonTitleValid = lessonTitle.trim() !== "";
    const isVideoUrlValid = videoUrl.trim() !== "";
    const isDescriptionValid = description.trim() !== "";
    const areQuestionsValid =
      questions.length > 0 &&
      questions.every(
        (q) =>
          q.question.trim() !== "" &&
          q.options.every((opt) => opt.trim() !== "") &&
          q.answer.trim() !== ""
      );

    if (
      isLessonIndexValid &&
      isLessonTitleValid &&
      isVideoUrlValid &&
      isDescriptionValid &&
      areQuestionsValid
    ) {
      const lessonData = {
        index: lessonIndex, // Guardar el índice de la lección
        title: lessonTitle,
        videoUrl,
        description,
        questions,
        createdAt: Date.now(),
      };

      if (editingLesson) {
        onUpdateLesson(editingLesson.id, lessonData);
      } else {
        onAddLesson(lessonData);
      }

      setLessonIndex("");
      setLessonTitle("");
      setVideoUrl("");
      setDescription("");
      setQuestions([]);
      setMessage("");
    } else {
      let errorMessage = "Por favor, completa todos los campos de la lección.";
      if (!isLessonIndexValid) errorMessage += " El índice de la lección está vacío.";
      if (!isLessonTitleValid) errorMessage += " El título de la lección está vacío.";
      if (!isVideoUrlValid) errorMessage += " La URL del video está vacía.";
      if (!isDescriptionValid) errorMessage += " La descripción de la lección está vacía.";
      if (!areQuestionsValid) errorMessage += " Asegúrate de que todas las preguntas y opciones estén completas.";
      setMessage(errorMessage);
    }
  };

  const handleCancelEdit = () => {
    setEditingLesson(null);
    setLessonIndex("");
    setLessonTitle("");
    setVideoUrl("");
    setDescription("");
    setQuestions([]);
    setMessage("");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Selección de Curso */}
      <div>
        <label htmlFor="courseSelect" className="block text-gray-700 font-semibold mb-2">
          Selecciona un Curso
        </label>
        <select
          id="courseSelect"
          value={selectedCourse ? selectedCourse.id : ""}
          onChange={(e) => onCourseChange(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200"
          required
          disabled={editingLesson}
        >
          <option value="">-- Selecciona un curso --</option>
          {courses.map((course) => (
            <option key={course.id} value={course.id}>
              {course.title}
            </option>
          ))}
        </select>
      </div>

      {/* Índice de la Lección */}
      <div>
        <label htmlFor="lessonIndex" className="block text-gray-700 font-semibold mb-2">
          Índice de la Lección
        </label>
        <input
          id="lessonIndex"
          type="text"
          placeholder="Ejemplo: 1, 1.1, 1.2, 2"
          value={lessonIndex}
          onChange={(e) => setLessonIndex(e.target.value)}
          className={`w-full p-3 border ${
            lessonIndex.trim() === "" ? "border-red-500" : "border-gray-300"
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          required
        />
        {lessonIndex.trim() === "" && (
          <p className="text-red-500 text-sm mt-1">El índice de la lección es requerido.</p>
        )}
      </div>

      {/* Título de la Lección */}
      <div>
        <label htmlFor="lessonTitle" className="block text-gray-700 font-semibold mb-2">
          Título de la Lección
        </label>
        <input
          id="lessonTitle"
          type="text"
          placeholder="Título de la Lección"
          value={lessonTitle}
          onChange={(e) => setLessonTitle(e.target.value)}
          className={`w-full p-3 border ${
            lessonTitle.trim() === "" ? "border-red-500" : "border-gray-300"
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          required
        />
        {lessonTitle.trim() === "" && (
          <p className="text-red-500 text-sm mt-1">El título de la lección es requerido.</p>
        )}
      </div>

      {/* URL del Video de YouTube */}
      <div>
        <label htmlFor="videoUrl" className="block text-gray-700 font-semibold mb-2">
          URL del Video de YouTube
        </label>
        <input
          id="videoUrl"
          type="url"
          placeholder="https://www.youtube.com/watch?v=ejemplo"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          className={`w-full p-3 border ${
            videoUrl.trim() === "" ? "border-red-500" : "border-gray-300"
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          required
        />
        {videoUrl.trim() === "" && (
          <p className="text-red-500 text-sm mt-1">La URL del video es requerida.</p>
        )}
      </div>

      {/* Descripción de la Lección */}
      <div>
        <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
          Descripción de la Lección
        </label>
        <textarea
          id="description"
          placeholder="Descripción de la Lección"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={`w-full p-3 border ${
            description.trim() === "" ? "border-red-500" : "border-gray-300"
          } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
          required
        />
        {description.trim() === "" && (
          <p className="text-red-500 text-sm mt-1">La descripción de la lección es requerida.</p>
        )}
      </div>

      {/* Sección de Preguntas */}
      <div>
        <h4 className="text-lg font-semibold text-secondary mb-4">Preguntas de la Lección</h4>
        {questions.map((q, qIndex) => (
          <div key={qIndex} className="mb-6 p-4 border border-gray-300 rounded-lg bg-gray-100">
            <div className="flex justify-between items-center mb-4">
              <span className="font-semibold">Pregunta {qIndex + 1}</span>
              <button
                type="button"
                onClick={() => handleRemoveQuestion(qIndex)}
                className="text-red-500 hover:text-red-600"
                aria-label={`Eliminar pregunta ${qIndex + 1}`}
              >
                <FaTrash />
              </button>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Pregunta
              </label>
              <input
                type="text"
                value={q.question}
                onChange={(e) =>
                  handleQuestionChange(qIndex, "question", e.target.value)
                }
                className={`w-full p-3 border ${
                  q.question.trim() === "" ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
                required
              />
              {q.question.trim() === "" && (
                <p className="text-red-500 text-sm mt-1">La pregunta es requerida.</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Opciones
              </label>
              <div className="grid grid-cols-2 gap-4">
                {q.options.map((option, oIndex) => (
                  <div key={oIndex}>
                    <label className="block text-gray-600 mb-1">
                      Opción {oIndex + 1}
                    </label>
                    <input
                      type="text"
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(qIndex, oIndex, e.target.value)
                      }
                      className={`w-full p-3 border ${
                        option.trim() === "" ? "border-red-500" : "border-gray-300"
                      } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
                      required
                    />
                    {option.trim() === "" && (
                      <p className="text-red-500 text-sm mt-1">La opción es requerida.</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Respuesta Correcta
              </label>
              <select
                value={q.answer}
                onChange={(e) =>
                  handleQuestionChange(qIndex, "answer", e.target.value)
                }
                className={`w-full p-3 border ${
                  q.answer.trim() === "" ? "border-red-500" : "border-gray-300"
                } rounded-md focus:outline-none focus:ring-primary focus:border-primary transition ease-in-out duration-200`}
                required
              >
                <option value="">-- Selecciona la respuesta correcta --</option>
                {q.options.map((option, oIndex) => (
                  <option key={oIndex} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {q.answer.trim() === "" && (
                <p className="text-red-500 text-sm mt-1">La respuesta correcta es requerida.</p>
              )}
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddQuestion}
          className="flex items-center gap-2 bg-secondary text-white py-2 px-4 rounded-lg hover:bg-secondary-hover transition-colors"
        >
          <FaPlus />
          Agregar Pregunta
        </button>
      </div>

      {/* Botones de Acción */}
      <div className="flex gap-4 mt-6">
        <button
          type="submit"
          className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors"
          disabled={processing}
        >
          {processing ? (
            <span className="flex items-center gap-2">
              <FaSpinner className="animate-spin" /> Guardando...
            </span>
          ) : (
            <span className="flex items-center gap-2">
              <FaSave /> Guardar
            </span>
          )}
        </button>
        {editingLesson && (
          <button
            type="button"
            onClick={handleCancelEdit}
            className="flex items-center gap-2 bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-colors"
          >
            <FaTimes />
            Cancelar
          </button>
        )}
      </div>
    </form>
  );
};

export default LessonForm;


