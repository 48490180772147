// src/components/Footer.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light py-5">
      <div className="container px-4 px-lg-5">
        <small className="text-center text-muted">
          Copyright &copy; 2024 - Corporación Educativa Pentecostal
        </small>
      </div>
    </footer>
  );
};

export default Footer;
