import React from "react";
import PropTypes from "prop-types";
import logo from '../../../assets/logo.png'; // Asegúrate de que la ruta al logo es correcta

const Certificate = ({ courseTitle, studentUser, completionDate }) => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white border-4 border-primary rounded-lg shadow-lg max-w-4xl w-full p-8 relative">
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Logo" className="h-24 w-auto" />
        </div>

        {/* Título del Certificado */}
        <h2 className="text-3xl font-bold text-center text-primary mb-4">
          Certificado de Finalización
        </h2>

        {/* Información del Estudiante */}
        <p className="text-center text-lg mb-2">
          Este certificado se otorga a:
        </p>
        <h4 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          {studentUser?.email || "Correo del Estudiante"}
        </h4>

        {/* Información del Curso */}
        <p className="text-center text-lg mb-2">
          Por completar con éxito el módulo:
        </p>
        <h5 className="text-3xl font-bold text-center text-secundary mb-4">
          {courseTitle || "Nombre del Módulo"}
        </h5>

        {/* Mensaje de Felicitación */}
        <p className="text-center text-lg italic mb-6">
          ¡Felicidades por tu esfuerzo y dedicación!
        </p>

        {/* Fecha de Finalización */}
        <p className="text-center text-sm text-gray-600">
          Fecha de finalización: {completionDate}
        </p>

        {/* Diseño Decorativo (Opcional) */}
        <div className="absolute bottom-4 left-0 right-0">
          <div className="border-t border-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

Certificate.propTypes = {
  courseTitle: PropTypes.string.isRequired,
  studentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  completionDate: PropTypes.string.isRequired, 
};

export default Certificate;


