// src/components/Question/Question.jsx

import React from 'react';
import PropTypes from 'prop-types';

const Question = ({ question, options, selectedOption, handleChange }) => (
  <div className="mb-6">
    <fieldset className="mb-4">
      <legend className="text-lg font-semibold text-primary mb-2">{question}</legend>
      <div className="space-y-2">
        {options.map((option, index) => (
          <label
            key={index}
            className={`flex items-center p-2 rounded-lg cursor-pointer transition-colors duration-200
              ${
                selectedOption === option
                  ? 'bg-primary text-white'
                  : 'bg-gray-100 hover:bg-gray-200'
              }
            `}
          >
            <input
              type="radio"
              name={`question-${question}`}
              value={option}
              checked={selectedOption === option}
              onChange={(e) => handleChange(e)}
              className="form-radio h-5 w-5 text-primary transition duration-150 ease-in-out"
            />
            <span className="ml-3">{option}</span>
          </label>
        ))}
      </div>
    </fieldset>
  </div>
);

Question.propTypes = {
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

export default Question;
