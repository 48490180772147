import React from 'react';

const HeaderProgram = ({ backgroundImage, height = '30vh', children }) => {
  return (
    <header
      className="masthead"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height,
      }}
    >
      {children}
    </header>
  );
};

export default HeaderProgram;
