// src/components/Teologia.jsx
import React from 'react';
import HeaderProgram from '../components/HeaderProgram';
import BotonEnlace from '../components/BotonEnlace';
import teologiaImage from '../assets/img/portfolio/fullsize/teologia.jpg'; 
import thumbnailImage1 from '../assets/img/portfolio/thumbnails/2.jpg';
import thumbnailImage2 from '../assets/img/portfolio/thumbnails/3.jpg';
import thumbnailImage3 from '../assets/img/portfolio/thumbnails/4.jpg';
import thumbnailImage4 from '../assets/img/portfolio/thumbnails/5.jpg';
import thumbnailImage5 from '../assets/img/portfolio/thumbnails/6.jpg';
import thumbnailImage6 from '../assets/img/portfolio/thumbnails/7.jpg';

const Teologia = () => {
  const planDeEstudio = [
    { title: 'Introducción a la Teología', description: '' },
    { title: 'Introducción a la Biblia', description: '' },
    { title: 'Antiguo Testamento', description: '' },
    { title: 'Monoteísmo', description: '' },
    { title: 'Doctrina', description: '' },
    { title: 'Homilética', description: '' },
    { title: 'Hermenéutica y Exégesis', description: '' },
    { title: 'Nuevo Testamento', description: '' },
    { title: 'Misionología y Evangelismo', description: '' },
    { title: 'Análisis de Religiones', description: '' },
    { title: 'Geografía Bíblica', description: '' },
    { title: 'Vida Cristiana', description: '' },
    { title: 'Gramática, comprensión y producción de textos', description: '' },
    { title: 'Psicología General', description: '' },
    { title: 'Didáctica y Pedagogía', description: '' },
    { title: 'Inglés Básico', description: '' },
  ];

  const requisitos = [
    { title: 'Edad', description: '15 años o más' },
    { title: 'Escolaridad', description: 'Certificado 9°' },
    { title: 'Copia', description: 'Documento de Identidad' },
    { title: 'Copia', description: 'Comprobante de Pago' },
  ];

  const imagenes = [
    { src: thumbnailImage1, link: '/bachillerato', alt: 'Imagen de Galería 1' },
    { src: thumbnailImage2, link: '/infancia', alt: 'Imagen de Galería 2' },
    { src: thumbnailImage3, link: '/administrativo', alt: 'Imagen de Galería 3' },
    { src: thumbnailImage4, link: '/musica', alt: 'Imagen de Galería 4' },
    { src: thumbnailImage5, link: '/ingles', alt: 'Imagen de Galería 5' },
    { src: thumbnailImage6, link: '/contable', alt: 'Imagen de Galería 6' },
  ];

  return (
    <div>
      {/* Header con Imagen de Fondo Relevante */}
      <HeaderProgram backgroundImage={teologiaImage} />

      {/* Contenido Principal */}
      <main className="container my-5">
        {/* Sección: Plan de Estudio */}
        <section>
          <h2 className="text-center">Plan de Estudio</h2>
          <ul>
            {planDeEstudio.map((curso, index) => (
              <li key={index}>{curso.title}</li>
            ))}
          </ul>
        </section>

        {/* Sección: Botón de Costos */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace
              className="btn btn-masinfo btn-xl"
              href="https://wa.me/573216200693?text=Hola,%20quiero%20más%20información%20sobre%20Teología%20y%20costos"
              target="_blank"
              rel="noopener noreferrer"
              title="Contacta con nosotros en WhatsApp"
            >
              <i className="bi-whatsapp me-2"></i> Costos y más información
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Requisitos */}
        <section className="my-5">
          <h2 className="text-center">Requisitos</h2>
          <div className="row justify-content-center">
            <div className="col-lg-4">
              {requisitos.map((req, index) => (
                <div key={index} className="course-info d-flex justify-content-between align-items-center">
                  <h5>{req.title}</h5>
                  <p>{req.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Sección: Botón de Pre-inscripción */}
        <section className="my-5 d-flex justify-content-center">
          <div className="col-lg-8 text-center">
            <BotonEnlace className="btn btn-primary btn-xl" href="/preinscripcion">
              Pre-inscribirme
            </BotonEnlace>
          </div>
        </section>

        {/* Sección: Galería */}
        <section className="my-5">
          <h2 className="text-center">Galería</h2>
          <div className="row">
            {imagenes.map((img, index) => (
              <div key={index} className="col-lg-4 col-md-6 mb-4">
                <a href={img.link}>
                  <img src={img.src} className="img-fluid" alt={img.alt} />
                </a>
              </div>
            ))}
          </div>
        </section>
      </main>

   
    </div>
  );
};

export default Teologia;
