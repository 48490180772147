import React from 'react';
import { FaUserPlus } from 'react-icons/fa';

const CreateUserButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center gap-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-hover transition-colors duration-200"
    >
      <FaUserPlus />
      Crear Estudiante
    </button>
  );
};

export default CreateUserButton;