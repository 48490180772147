import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Switch, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EnrolledCourses = ({
  enrolledCourses,
  studentModules,
  setStudentModules,
  handleActivateModule,
  handleDeactivateModule,
  updatingModules,
  fetchStudentModules 
}) => {
  const [localModules, setLocalModules] = useState(studentModules);

  // Sincronizar `localModules` con `studentModules`
  useEffect(() => {
    setLocalModules(studentModules);
  }, [studentModules]);

  const handleSwitchChange = async (courseId, moduleId, isActive) => {
    try {
      if (isActive) {
        await handleDeactivateModule(courseId, moduleId);
      } else {
        await handleActivateModule(courseId, moduleId);
      }

      // Refrescar los módulos después de la actualización
      await fetchStudentModules();
    } catch (error) {
      console.error("Error al cambiar el estado del módulo:", error);
    }
  };

  return (
    <div className="space-y-4">
      {enrolledCourses.map((course) => (
        <Accordion key={course.id} className="bg-gray-50 rounded-md shadow-sm">
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-white">
            <span className="font-semibold text-gray-800">{course.title}</span>
          </AccordionSummary>
          <AccordionDetails className="bg-white">
            {localModules[course.id]?.length > 0 ? (
              <div className="space-y-2">
                {localModules[course.id].map((module) => (
                  <div
                    key={module.id}
                    className={`flex justify-between items-center p-2 rounded-md transition duration-300 ease-in-out ${
                      module.isActive ? 'bg-green-100' : 'bg-red-100'
                    }`}
                  >
                    <span className="text-sm text-gray-700">{module.title}</span>

                    {updatingModules[`${course.id}-${module.id}`] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Switch
                        checked={module.isActive || false}
                        onChange={() => handleSwitchChange(course.id, module.id, module.isActive)}
                        color="primary"
                        size="small"
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <span className="text-sm text-gray-500">No hay módulos disponibles.</span>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default EnrolledCourses;










