// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAdminAuth } from '../hooks/useAdminAuth';

const ProtectedRoute = ({ children }) => {
  const { adminUser } = useAdminAuth();

  if (!adminUser) {
    // Si no hay un usuario administrador autenticado, redirige al login
    return <Navigate to="/admin" />;
  }

  // Si el usuario está autenticado, renderiza el componente
  return children;
};

export default ProtectedRoute;
