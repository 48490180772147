import React, { useState, useEffect } from "react";
import {
  getCourses,
  addCourse,
  updateCourse,
  deleteCourse,
} from "../../firebase/firestore/courses";
import CourseForm from "./CourseForm";
import CourseItem from "./CourseItem";
import { Box, Typography, Alert, CircularProgress, Paper, List } from "@mui/material";

const AdminCourses = () => {
  const [courses, setCourses] = useState([]);
  const [editingCourse, setEditingCourse] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    setLoadingCourses(true);
    try {
      const courseData = await getCourses();
      setCourses(courseData);
      setError("");
    } catch (error) {
      setError("Error al cargar los cursos.");
      console.error(error);
    } finally {
      setLoadingCourses(false);
    }
  };

  const handleCourseSubmit = async (courseData) => {
    setProcessing(true);
    try {
      if (editingCourse) {
        await updateCourse(editingCourse.id, courseData);
        setMessage("Curso actualizado exitosamente.");
      } else {
        await addCourse(courseData);
        setMessage("Curso creado exitosamente.");
      }
      setEditingCourse(null);
      fetchCourses();
    } catch (error) {
      setError("Error al procesar la solicitud.");
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleCourseDelete = async (courseId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este curso?")) {
      setProcessing(true);
      try {
        await deleteCourse(courseId);
        setMessage("Curso eliminado exitosamente.");
        fetchCourses();
      } catch (error) {
        setError("Error al eliminar el curso.");
        console.error(error);
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-center text-3xl font-bold text-primary mb-8">Administrar Cursos</h2>
  
      {/* Alertas de Mensajes */}
      {message && (
        <Alert severity="success" className="mb-6">
          {message}
        </Alert>
      )}
      {error && (
        <Alert severity="error" className="mb-6">
          {error}
        </Alert>
      )}
  
      {/* Formulario para Agregar/Editar Curso */}
      <Paper elevation={3} className="mb-8 p-6 shadow-md rounded-lg">
        <CourseForm
          onSubmit={handleCourseSubmit}
          editingCourse={editingCourse}
          setEditingCourse={setEditingCourse}
          setMessage={setMessage}
          setError={setError}
          processing={processing}
        />
      </Paper>
  
      {/* Lista de Cursos */}
      <h2 className="text-center text-3xl font-bold text-primary mb-8">Cursos Disponibles</h2>
      {loadingCourses ? (
        <Box className="flex justify-center items-center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Paper elevation={3} className="p-6 shadow-md rounded-lg">
          {courses.length === 0 ? (
            <Typography variant="body1" className="text-gray-700">
              No hay cursos disponibles.
            </Typography>
          ) : (
            <List className="space-y-4">
              {courses
                .sort((a, b) => a.title.localeCompare(b.title)) // Ordenar cursos alfabéticamente
                .map((course) => (
                  <CourseItem
                    key={course.id}
                    course={course}
                    onDelete={() => handleCourseDelete(course.id)}
                    onEdit={() => setEditingCourse(course)}
                  />
                ))}
            </List>
          )}
        </Paper>
      )}
    </div>
  );
  
};

export default AdminCourses;

