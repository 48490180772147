// AdminUsers.jsx

import React, { useState, useEffect, useCallback } from "react";
import { getCourses } from "../../firebase/firestore/courses";
import {
  addStudent,
  getStudents,
  enrollStudentInCourse,
  removeStudent,
  removeStudentFromCourse,
  updateStudentName,
  getStudentByEmail,
} from "../../firebase/firestore/students";
import {
  getCourseModules,
  activateStudentModule,
  deactivateStudentModule,
  listenToModules,
} from "../../firebase/firestore/modules";

// Importar subcomponentes
import CreateUserButton from "./CreateUserButton";
import CreateUserForm from "./CreateUserForm";
import StudentsList from "./StudentsList";
import StudentProfileModal from "./StudentProfileModal";
import { FaSpinner } from "react-icons/fa";

const AdminUsers = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [courses, setCourses] = useState([]);
  const [studentEmail, setStudentEmail] = useState("");
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [enrolling, setEnrolling] = useState(false);
  const [updatingModules, setUpdatingModules] = useState({});
  const [processing, setProcessing] = useState(false); 
  const [studentFirstName, setStudentFirstName] = useState("");
  const [studentLastName, setStudentLastName] = useState("");
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [studentModules, setStudentModules] = useState({});
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [forceRender, setForceRender] = useState(false);

  // Función para obtener todos los estudiantes
  const fetchStudentsList = useCallback(async () => {
    setLoadingStudents(true);
    setError("");
    try {
      const studentsData = await getStudents();
      setStudents(studentsData);
      console.log("Students Data:", studentsData);
      return studentsData; // Devolver los datos
    } catch (err) {
      setError("Error al obtener los estudiantes.");
      console.error(err);
      return null;
    } finally {
      setLoadingStudents(false);
    }
  }, []);

  // Función para obtener todos los cursos
  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    setError("");
    try {
      const courseData = await getCourses();
      setCourses(courseData);
      console.log("Courses Data:", courseData);
    } catch (err) {
      setError("Error al obtener los cursos.");
      console.error(err);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  // Función para obtener los cursos inscritos y sus módulos
  const fetchEnrolledCourses = useCallback(
    async (student) => {
      if (!student) return;
      setError("");
      try {
        const enrolledCourseIds = Object.keys(student.enrolledCourses || {});
        const enrolledCoursesData = courses.filter((course) =>
          enrolledCourseIds.includes(course.id)
        );
        setEnrolledCourses(enrolledCoursesData);
        console.log("Enrolled Courses:", enrolledCoursesData);
  
        // Obtener módulos para cada curso inscrito
        const modulesPromises = enrolledCoursesData.map(async (course) => {
          const modules = await getCourseModules(course.id); // Obtener módulos del curso
  
          // Mapear cada módulo y asignar `isActive` basado en los datos del estudiante
          return modules.map((module) => {
            const isActive =
              student.enrolledCourses[course.id]?.modules?.[module.id]?.isActive || false;
            return { ...module, isActive };
          });
        });
  
        const modulesResults = await Promise.all(modulesPromises);
        const modulesMap = {};
        enrolledCoursesData.forEach((course, index) => {
          modulesMap[course.id] = modulesResults[index];
        });
        setStudentModules(modulesMap);
        console.log("Modules Map:", modulesMap);
      } catch (err) {
        setError("Error al obtener los cursos y módulos del estudiante.");
        console.error(err);
      }
    },
    [courses]
  );

  useEffect(() => {
    fetchStudentsList();
    fetchCourses();
  }, [fetchCourses, fetchStudentsList]);

  // Función para manejar la creación de un nuevo usuario
  const handleCreateUser = async () => {
    if (!studentEmail || typeof studentEmail !== "string") {
      setError("Por favor, proporciona un correo válido.");
      return;
    }
    const trimmedEmail = studentEmail.trim().toLowerCase(); // Normalizar el correo
    const trimmedFirstName = studentFirstName.trim();
    const trimmedLastName = studentLastName.trim();
    setEnrolling(true);
    setError("");
    try {
      const accessCode = await addStudent({
        email: trimmedEmail,
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
      });
      setMessage(
        `Estudiante creado con éxito. Código de acceso: ${accessCode}`
      );
      setStudentEmail("");
      setStudentFirstName(""); // Limpiar los campos
      setStudentLastName("");
      const updatedStudents = await fetchStudentsList(); // Actualizar la lista de estudiantes
    } catch (err) {
      setError("Error al crear el estudiante.");
      console.error(err);
    } finally {
      setEnrolling(false);
    }
  };

  // Función para seleccionar un estudiante
  const handleSelectStudent = useCallback(
    async (student) => {
      console.log(`Seleccionando estudiante: ${student.email}`);
      setSelectedStudent(student);
      await fetchEnrolledCourses(student);
      setMessage("");
      setError("");
      console.log("Selected Student:", student);
    },
    [fetchEnrolledCourses]
  );

  // Función para eliminar un estudiante
  const handleRemoveStudent = useCallback(
    async (studentEmail) => {
      setError("");
      try {
        await removeStudent(studentEmail); // Eliminar completamente el estudiante
        setMessage("Estudiante eliminado exitosamente.");
        setSelectedStudent(null);
        await fetchStudentsList();
        console.log(`Removed Student: ${studentEmail}`);
      } catch (err) {
        setError("Error al eliminar al estudiante.");
        console.error(err);
      }
    },
    [fetchStudentsList]
  );

  // Definir la función fetchStudentModules en AdminUsers.jsx
const fetchStudentModules = async () => {
  if (!selectedStudent) return;

  try {
    const enrolledCourseIds = Object.keys(selectedStudent.enrolledCourses || {});
    const modulesMap = {};

    for (const courseId of enrolledCourseIds) {
      const courseModules = await getCourseModules(courseId); // Obtener módulos de `courses`
      modulesMap[courseId] = courseModules.map((module) => ({
        ...module,
        isActive: selectedStudent.enrolledCourses[courseId]?.modules?.[module.id]?.isActive || false
      }));
    }

    setStudentModules(modulesMap);
    console.log("Modules re-fetched and updated in AdminUsers.");
  } catch (error) {
    console.error("Error al actualizar módulos desde students:", error);
  }
};


  // Función para actualizar el nombre del estudiante
  const handleUpdateStudent = async (email, firstName, lastName) => {
    setError("");
    try {
      await updateStudentName(email, firstName, lastName); // Asegúrate de que esta función actualiza correctamente Firestore
      setMessage("Estudiante actualizado exitosamente.");
      const updatedStudents = await fetchStudentsList(); // Re-fetchear la lista de estudiantes
      const updatedStudent = updatedStudents?.find(s => s.email === email);
      if (updatedStudent) {
        setSelectedStudent(updatedStudent);
        await fetchEnrolledCourses(updatedStudent);
      }
    } catch (err) {
      setError("Error al actualizar el estudiante.");
      console.error(err);
    }
  };

  // **Funciones corregidas para activar y desactivar módulos**
  const handleActivateModule = async (courseId, moduleId) => {
    if (!selectedStudent) return;
    setUpdatingModules((prev) => ({ ...prev, [`${courseId}-${moduleId}`]: true }));
    setError("");
    try {
      await activateStudentModule(courseId, moduleId, selectedStudent.email);
      setMessage("Módulo activado exitosamente.");
  
      // Re-fetchear el estudiante actualizado
      const updatedStudent = await getStudentByEmail(selectedStudent.email);
      setSelectedStudent(updatedStudent);
  
      // Re-fetchear los módulos
      await fetchEnrolledCourses(updatedStudent);
    } catch (err) {
      setError("Error al activar el módulo.");
      console.error(err);
    } finally {
      setUpdatingModules((prev) => {
        const updated = { ...prev };
        delete updated[`${courseId}-${moduleId}`];
        return updated;
      });
    }
  };

  const fetchModules = useCallback(async () => {
    try {
      const modulesWithIsActive = await initializeModules(enrolledCourses); // Pasa los cursos inscritos
      setStudentModules(modulesWithIsActive);
      console.log("Modules re-fetched and updated in AdminUsers.");
    } catch (error) {
      console.error("Error al cargar módulos:", error);
    }
  }, [enrolledCourses]);
  
  // Uso de `initializeModules` al cargar `studentModules` cuando cambia `enrolledCourses`
  useEffect(() => {
    fetchModules();
  }, [enrolledCourses]);

  const handleDeactivateModule = async (courseId, moduleId) => {
    if (!selectedStudent) return;
    setUpdatingModules((prev) => ({ ...prev, [`${courseId}-${moduleId}`]: true }));
    setError("");
    try {
      await deactivateStudentModule(courseId, moduleId, selectedStudent.email);
      setMessage("Módulo desactivado exitosamente.");
  
      // Re-fetchear el estudiante actualizado
      const updatedStudent = await getStudentByEmail(selectedStudent.email);
      setSelectedStudent(updatedStudent);
  
      // Re-fetchear los módulos
      await fetchEnrolledCourses(updatedStudent);
    } catch (err) {
      setError("Error al desactivar el módulo.");
      console.error(err);
    } finally {
      setUpdatingModules((prev) => {
        const updated = { ...prev };
        delete updated[`${courseId}-${moduleId}`];
        return updated;
      });
    }
  };

  const initializeModules = async (courses) => {
    const initializedModules = {};
    for (const course of courses) {
      const modules = await getCourseModules(course.id); // Usa getCourseModules para obtener módulos
      initializedModules[course.id] = modules.map((module) => ({
        ...module,
        isActive: module.isActive ?? false, // Inicializa `isActive` si está undefined
      }));
    }
    return initializedModules;
  };
  
  useEffect(() => {
    const unsubscribes = enrolledCourses.map((course) => {
      console.log(`Setting up listener for course modules: ${course.id}`);
      return listenToModules(course.id, (modules) => {
        console.log(`Modules updated from Firestore for course ${course.id}:`, modules);
        setStudentModules((prevModules) => ({
          ...prevModules,
          [course.id]: modules,
        }));
        setForceRender((prev) => !prev); // Forzar el re-render
      });
    });

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [enrolledCourses]);

  // Uso de `initializeModules` al cargar `studentModules`
  useEffect(() => {
    const fetchModules = async () => {
      try {
        const modulesWithIsActive = await initializeModules(enrolledCourses); // Pasa los cursos inscritos
        setStudentModules(modulesWithIsActive);
      } catch (error) {
        console.error("Error al cargar módulos:", error);
      }
    };
  
    fetchModules();
  }, [enrolledCourses]); // Se actualiza cuando los cursos inscritos cambian

 

  // Función para inscribir al estudiante en un curso
  const handleEnrollStudent = async (courseId) => {
    if (!selectedStudent) {
      setError("Por favor, selecciona un estudiante primero.");
      return;
    }

    setProcessing(true); // Establecer processing al inicio

    // Verificar si el estudiante ya está inscrito en el curso
    const isAlreadyEnrolled = enrolledCourses.some(
      (enrolled) => enrolled.id === courseId
    );
    if (isAlreadyEnrolled) {
      setError("El estudiante ya está inscrito en este curso.");
      setProcessing(false); // Resetear processing
      return;
    }

    try {
      console.log(`Intentando inscribir al curso con ID: ${courseId}`);
      await enrollStudentInCourse(selectedStudent.email, courseId);

      // Obtener el título del curso para el mensaje
      const course = courses.find((c) => c.id === courseId);
      const courseTitle = course ? course.title : courseId;
      setMessage(`Estudiante inscrito en el curso "${courseTitle}" exitosamente.`);

      // Re-fetchear la lista de estudiantes para obtener datos actualizados
      const updatedStudents = await fetchStudentsList();

      // Encontrar el estudiante actualizado en la lista
      const updatedStudent = updatedStudents?.find(s => s.email === selectedStudent.email);
      if (updatedStudent) {
        setSelectedStudent(updatedStudent); // Actualizar el estado del estudiante seleccionado
        await fetchEnrolledCourses(updatedStudent); // Actualizar los cursos inscritos y módulos
      }

    } catch (err) {
      setError(err.message || "Error al inscribir el estudiante en el curso.");
      console.error("Error al inscribir el estudiante en el curso:", err);
    } finally {
      setProcessing(false); // Finalizar el estado de procesamiento
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-primary mb-6 text-center">
        Administrar Usuarios
      </h2>

      {/* Alertas de Mensajes */}
      {message && (
        <div className="bg-green-100 text-green-800 p-4 rounded-lg mb-6">
          {message}
        </div>
      )}
      {error && (
        <div className="bg-red-100 text-red-800 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Sección para Listar y Seleccionar Estudiantes */}
      <div className="mb-6">
        <StudentsList
          students={students}
          loading={loadingStudents}
          handleSelectStudent={handleSelectStudent}
          handleRemoveStudent={handleRemoveStudent}
          handleUpdateStudent={handleUpdateStudent} // Asegúrate de que esta función exista
        />
      </div>

      {/* Botón para Crear un Usuario */}
      <div className="mb-6">
        <CreateUserButton onClick={() => setShowCreateUserModal(true)} />
      </div>

      {/* Perfil del Estudiante Seleccionado */}
      {selectedStudent && (
        <StudentProfileModal
          key={selectedStudent.email} // Agregar key para forzar re-render
          student={selectedStudent}
          courses={courses}
          enrolledCourses={enrolledCourses}
          studentModules={studentModules}
          setStudentModules={setStudentModules}
          loadingCourses={loadingCourses}
          handleEnrollStudent={handleEnrollStudent}
          handleActivateModule={handleActivateModule}
          handleDeactivateModule={handleDeactivateModule}
          handleUnenrollCourse={removeStudentFromCourse}
          updatingModules={updatingModules}
          fetchModules={fetchModules}
          fetchStudentModules={fetchStudentModules}
          processing={processing} 
          onClose={() => setSelectedStudent(null)}
        />
      )}

      {/* Modal para Crear Usuario */}
      {showCreateUserModal && (
        <CreateUserForm
          studentEmail={studentEmail}
          setStudentEmail={setStudentEmail}
          studentFirstName={studentFirstName}
          setStudentFirstName={setStudentFirstName}
          studentLastName={studentLastName}
          setStudentLastName={setStudentLastName}
          handleCreateUser={handleCreateUser}
          enrolling={enrolling}
          onClose={() => setShowCreateUserModal(false)}
        />
      )}
    </div>
  );
};

export default AdminUsers;



