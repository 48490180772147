// src/components/Header/Header.jsx
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStudentAuth } from "../../hooks/useStudentAuth";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebaseConfig";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import styles from "./Header.module.css";
import icon from "../../../assets/icon.png";

const Header = () => {
  const { studentUser, setStudentUser } = useStudentAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setStudentUser(null);
      localStorage.removeItem("studentUser");
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <>
          <div className={styles.logoContainer}>
            <img
              src={icon}
              alt="Logo"
              className="navbar-logo me-2"
              style={{ width: "100px", height: "auto" }}
            />
            <Link to="/" className={styles.logo}>
              <h2>Plataforma LMS CEP</h2>
            </Link>
          </div>
        </>

        <ul className={styles.navList}>
          <li>
            <Link to="/campus" className={styles.navLink}>
              Inicio
            </Link>
          </li>
          {!studentUser && (
            <li>
              <Link to="/campus/login" className={styles.navLink}>
                Iniciar Sesión
              </Link>
            </li>
          )}
          {studentUser && (
            <>
              <li className={styles.userInfo}>
                <span className={styles.userEmail}>{studentUser.email}</span>
              </li>
              <li>
                <button onClick={handleLogout} className={styles.logoutButton}>
                  Cerrar Sesión
                </button>
              </li>
            </>
          )}
          <li>
            <Link to="/campus/admin" className={styles.navLink}>
              Admin
            </Link>
          </li>
          <li>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
