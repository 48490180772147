// StudentProfileModal.jsx

import React, { useState } from 'react';
import {
  Tabs,
  Tab,
  Avatar,
  CircularProgress
} from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import EnrolledCourses from './EnrolledCourses';
import EnrollCourseForm from './EnrollCourseForm';
import { FaTimes } from 'react-icons/fa';

const StudentProfileModal = ({
  student,
  courses,
  enrolledCourses,
  studentModules,
  setStudentModules,
  loadingCourses,
  handleEnrollStudent,
  handleActivateModule,
  handleDeactivateModule,
  handleUnenrollCourse,
  updatingModules,
  fetchModules,
  fetchStudentModules,
  processing, 
  onClose, // Prop para cerrar el modal
}) => {
  // Estado para controlar la pestaña activa
  const [activeTab, setActiveTab] = useState(0);

  // Función para manejar el cambio de pestaña
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-75 overflow-auto mt-32">
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-3xl max-h-[90vh] overflow-y-auto">
        
        {/* Encabezado Fijo */}
        <div className="flex justify-between items-center sticky top-0 bg-white p-4 z-10 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Perfil del Estudiante</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
            <FaTimes />
          </button>
        </div>

        {/* Información del Estudiante */}
        <div className="bg-gray-50 p-4 rounded-lg mb-6 shadow-sm">
          <div className="flex items-center">
            <Avatar sx={{ bgcolor: 'primary.main', marginLeft: 10, marginRight: 2, width: 80, height: 80 }}>
              <PersonIcon sx={{ fontSize: 40 }} />
            </Avatar>
            <div>
              <h2 className="text-lg font-bold ">{student.firstName} {student.lastName}</h2>
              <p className="text-sm text-primary font-semibold">{student.email}</p>
              <p className="text-sm text-gray-600">
                <strong>Código de Acceso:</strong> {student.accessCode}
              </p>
            </div>
          </div>
        </div>

        {/* Pestañas */}
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className="bg-white z-10"
        >
          <Tab label="Inscribir en Curso" />
          <Tab label="Cursos Inscritos" />
        </Tabs>

        {/* Contenido de las Pestañas */}
        {activeTab === 0 && (
          <div className="p-4">
            {loadingCourses ? (
              <div className="flex justify-center p-6">
                <CircularProgress />
              </div>
            ) : (
              <EnrollCourseForm
                key={enrolledCourses.length} // Agregar esta línea
                courses={courses}
                handleEnrollStudent={handleEnrollStudent}
                enrolledCourses={enrolledCourses}
                loadingCourses={loadingCourses}
                processing={processing} // Pasar el estado de procesamiento
              />
            )}
          </div>
        )}

        {activeTab === 1 && (
          <div className="p-4">
            <EnrolledCourses
              enrolledCourses={enrolledCourses}
              studentModules={studentModules}
              setStudentModules={setStudentModules}
              handleActivateModule={handleActivateModule}
              handleDeactivateModule={handleDeactivateModule}
              handleUnenrollCourse={handleUnenrollCourse}
              updatingModules={updatingModules}
              fetchModules={fetchModules}
              fetchStudentModules={fetchStudentModules}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentProfileModal;



