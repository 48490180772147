// src/components/Exam/Exam.jsx

import React, { useState } from "react";
import PropTypes from 'prop-types';

const Exam = ({ questions, onSubmit }) => {
  const [selectedAnswers, setSelectedAnswers] = useState(Array(questions.length).fill(null));

  const handleOptionChange = (questionIndex, value) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[questionIndex] = value;
    setSelectedAnswers(updatedAnswers);
    console.log(`Pregunta ${questionIndex + 1} seleccionada: ${value}`); // Para depuración
  };

  const handleSubmit = () => {
    console.log("Enviar examen clickeado"); // Para depuración
    // Verificar si todas las preguntas han sido respondidas
    if (selectedAnswers.includes(null)) {
      alert("Por favor, responde todas las preguntas antes de enviar el examen.");
      return;
    }

    const correctAnswersCount = questions.reduce((count, question, index) => {
      return count + (selectedAnswers[index] === question.answer ? 1 : 0);
    }, 0);

    const score = (correctAnswersCount / questions.length) * 100;
    console.log(`Score: ${score}%`); // Para depuración
    onSubmit(score);
  };

  return (
    <div className="bg-card-background p-6 rounded-lg shadow-md max-w-4xl w-full mx-auto">
      <h2 className="text-2xl font-semibold text-primary mb-6">Examen Final</h2>
      {questions.map((question, index) => (
        <div key={index} className="mb-6">
          <p className="text-lg font-medium text-text-color mb-2">{question.question}</p>
          <div className="space-y-2">
            {question.options.map((option, optionIndex) => (
              <label
                key={optionIndex}
                className={`flex items-center p-2 rounded-lg cursor-pointer transition-colors duration-200
                  ${
                    selectedAnswers[index] === option
                      ? 'bg-primary text-white'
                      : 'bg-gray-100 hover:bg-gray-200'
                  }
                `}
              >
                <input
                  type="radio"
                  name={`exam-question-${index}`}
                  value={option}
                  checked={selectedAnswers[index] === option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  className="form-radio h-5 w-5 text-primary transition duration-150 ease-in-out"
                />
                <span className="ml-3">{option}</span>
              </label>
            ))}
          </div>
        </div>
      ))}
      <button
        onClick={handleSubmit}
        className="w-full bg-primary hover:bg-primary-hover text-white py-3 px-6 rounded-lg transition-colors duration-300 font-semibold"
      >
        Enviar Examen
      </button>
    </div>
  );
};

Exam.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.string).isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Exam;

