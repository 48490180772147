import React, { useState } from "react";
import AdminCourses from "../AdminCourses/AdminCourses";
import AdminLessons from "../AdminLessons/AdminLessons";
import AdminUsers from "../AdminUsers/AdminUsers";
import AdminProgress from "../AdminProgress/AdminProgress";
import AdminModules from "../AdminModules/AdminModules";

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState("courses");

  const renderSection = () => {
    switch (activeSection) {
      case "courses":
        return <AdminCourses />;
      case "lessons":
        return <AdminLessons />;
      case "modules":
        return <AdminModules />;
      case "users":
        return <AdminUsers />;
      case "progress":
        return <AdminProgress />;
      default:
        return <AdminCourses />;
    }
  };

  return (
    <div className="flex min-h-screen bg-background">
      {/* Sidebar */}
      <aside className="w-64 bg-gradient-to-br from-primary to-secondaryd p-6 text-white shadow-lg">
        <h2 className="text-2xl font-bold text-center mb-8">Panel de Administración</h2>
        <nav>
          <ul className="space-y-4">
            <li
              className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                activeSection === "courses" ? "bg-white text-primary font-semibold" : "hover:bg-white hover:text-primary"
              }`}
              onClick={() => setActiveSection("courses")}
            >
              Administrar Cursos
            </li>
            <li
              className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                activeSection === "modules" ? "bg-white text-primary font-semibold" : "hover:bg-white hover:text-primary"
              }`}
              onClick={() => setActiveSection("modules")}
            >
              Administrar Módulos
            </li>
            <li
              className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                activeSection === "lessons" ? "bg-white text-primary font-semibold" : "hover:bg-white hover:text-primary"
              }`}
              onClick={() => setActiveSection("lessons")}
            >
              Administrar Lecciones
            </li>
            <li
              className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                activeSection === "users" ? "bg-white text-primary font-semibold" : "hover:bg-white hover:text-primary"
              }`}
              onClick={() => setActiveSection("users")}
            >
              Administrar Usuarios
            </li>
            <li
              className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                activeSection === "progress" ? "bg-white text-primary font-semibold" : "hover:bg-white hover:text-primary"
              }`}
              onClick={() => setActiveSection("progress")}
            >
              Ver Progreso de Estudiantes
            </li>
          </ul>
        </nav>
      </aside>

      {/* Contenido Principal */}
      <main className="flex-grow p-8 bg-white rounded-lg shadow-md overflow-y-auto">
        {renderSection()}
      </main>
    </div>
  );
};

export default AdminDashboard;
