// src/hooks/useAdminAuth.js
import { useContext, createContext, useState, useEffect } from 'react';
import { auth } from '../firebase/firebaseConfig';
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

const AdminAuthContext = createContext();

export function AdminAuthProvider({ children }) {
  const [adminUser, setAdminUser] = useState(null);

  useEffect(() => {
    const setAuthPersistence = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (error) {
        console.error('Error al establecer la persistencia de autenticación para el administrador:', error);
      }
    };

    setAuthPersistence();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // Aquí podrías agregar una verificación adicional para asegurarte de que sea un administrador
      setAdminUser(user);
    });

    return () => unsubscribe();
  }, []);

  return <AdminAuthContext.Provider value={{ adminUser }}>{children}</AdminAuthContext.Provider>;
}

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};
